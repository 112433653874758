.VideoElement {

	&__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

	&__Video {
		margin-top: 30px;

		@include breakpoint($screen-md) {
			margin-top: 40px;
		}
	}

}
