/* Cookie Consent
-------------------------------------------------- */

.cookie-consent {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;

  .container {
    width: 100%;

    // Make 'em scrollable
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .container,
  .row {
    height: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    text-align: center;
  }

  .col {
    width: 100%;
    max-width: 800px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    padding: 10px;
  }

  .Text {
    font-size: 16px;
    line-height: 1.54;
  }

  &__headline {
    margin: 0;
    font-size: 20px;

    @media (min-width: $screen-sm) {
      font-size: 24px;
		}
  }

  &__box {
    background: #FFF;
    box-shadow: 0 .5rem 1.5rem 0 rgba(0, 0, 0, 0.15);
    padding: 3rem 2rem;
  }

  .headline {
    hyphens: none;
    margin-bottom: 1.5rem;
  }

  // Adapt Prokon style
  a {
    background-size: 100% 1px;
  }

  &__types {
    margin: 3rem 0;
    padding: 0;
    line-height: 2;

    li {
      display: inline-block;

      + li {
        margin-left: 1em;
      }
    }

    .input {
      margin: 0;

      label {
        display: inline-block;
        font-size: inherit;
        cursor: pointer;
      }

      input {
        display: none;

        + span {
          margin-left: 0.2em;

          &::before {
            content: "✓";
            font-size: 1.6em;
            font-weight: 300;
            line-height: 0;
            margin-right: 0.2em;
            vertical-align: sub;
            color: $link-color;
            border: 0;
            opacity: .2;
          }
        }

        &:hover:not(:checked) + span,
        &:checked + span {
          text-decoration: none;

          &::before {
            opacity: 1;
          }
        }

        &:checked + span {
          &::before {
            color: $color-green;
          }
        }
      }
    }
  }

  .button-group {
    margin-bottom: 3rem;

    .button {
      font-size: 1.5rem;
    }

    .button--plain {
      text-decoration: none;
      font-weight: 500;
      margin-right: 1.5rem;

      &::before,
      &::after {
    		content: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__meta {
    opacity: .55;

    &:hover {
      opacity: 1;
    }
  }
}

// Display cookie hint
html:not(.type-meta-page) .cookie-consent.is-active {
  display: block;
}
