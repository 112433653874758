.FormField {
  padding-bottom: 0;
  transition: 0.25s padding;
  position: relative;

  &__Label {
    @include font-p-small();
    margin: 20px 0 10px 0;
  }

  &__Status {
    position: absolute;
    bottom: 0;
    transition: 0.25s all;
    opacity: 0;
    transform: translateX(10%);
    padding-top: 7px;
    z-index: 1;
  }

  &__Input {
    -webkit-appearance: none;
    height: 48px;
    border-radius: 5px;
    @include font-p-bold();
    padding: 0 15px;
    width: 100%;
    position: relative;
    z-index: 2;

    &--Textarea {
      height: auto;
    }

    border: 1px solid rgba(25, 25, 25, 0.2);
    &:focus {
      border: 2px solid $color-green;
    }

    &:disabled {
      background-color: rgba(25, 25, 25, 0.07);
    }

    &::placeholder {
      color: rgba(25, 25, 25, 0.2);
    }

    &--TextArea {
      height: auto;
    }
  }

  &__InputWrapper {
    position: relative;
  }

  &__Select {
    white-space: nowrap;
    padding: 0 45px 0 15px;
    border-radius: 5px;
    border: 1px solid rgba(25, 25, 25, 0.2);
    outline: none;

    @include inline-svg($icon-arrow-down);
    width: 100%;
    height: auto;
    background-size: 20px;
    background-position-x: 90%;

    font-family: $font-family-primary-bold;
    font-size: 17px;
    appearance: none;

    position: relative;
    z-index: 2;

    line-height: 46px;

    &:focus {
      border: 2px solid $color-green;
      line-height: 44px;
    }
  }

  // RADIO GROUP
  //________________________________________________________________

  &__RadioButtonContainer {
    position: relative;

    input[type="radio"] {
      position: absolute;
      opacity: 0;
    }

    input[type="radio"]:checked ~ .FormField__RadioButtonCheck {
      border: 2px solid $color-green;
    }

    input[type="radio"]:checked ~ .FormField__RadioButtonCheck::before {
      background: $color-green;
      height: 14px;
      width: 14px;
    }

    input[type="radio"]:checked ~ .FormField__RadioButtonLabel {
      color: $color-black;
    }
  }

  &__RadioButtonLabel {
    display: block;
    position: relative;
    @include font-p-bold();
    padding: 0 25px 0 50px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
  }

  &__RadioButtonCheck {
    display: block;
    position: absolute;
    border: 1px solid rgba(25, 25, 25, 0.2);
    border-radius: 100%;
    height: 36px;
    width: 36px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 5;

    &::before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 16px;
      width: 16px;
      top: 9px;
      left: 9px;
      margin: auto;
    }
  }

  &__RadioButton {
    display: inline-block;
    position: relative;
    z-index: 2;
    padding-right: 30px;
    &--block {
      display: block;
      padding-bottom: 5px;
    }
  }

  // RADIO GROUP
  //________________________________________________________________

  &__Checkbox {
    position: relative;
    z-index: 2;
    margin-top: 30px;
    input[type="checkbox"] {
      position: absolute;
      visibility: hidden;
    }

    input[type="checkbox"]:checked ~ .FormField__CheckboxLabel,
    input.checked ~ .FormField__CheckboxLabel {
      &:before {
        border: 2px solid $color-green;
        @include inline-svg($icon-check, $color-green);
        background-size: 62%;
        width: 25px;
        height: 25px;
      }
    }
  }

  &__CheckboxLabel {
    display: block;
    position: relative;
    @include font-p();
    padding: 0 25px 0 38px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;

    &:before {
      content: "";
      position: absolute;

      left: 0;
      width: 25px;
      height: 25px;
      border: 1px solid rgba(25, 25, 25, 0.2);
      border-radius: 5px;
      top: 0.2em;
    }
  }

  &.error {
    padding-bottom: 46px;
    .FormField__Input {
      border: 2px solid $color-error;
    }

    .FormField__Status {
      transform: translate(0, 100%);
      opacity: 1;
      color: $color-error;
    }

    .FormField__Label {
      color: $color-error;
    }

    .FormField__RadioButtonCheck {
      border: 2px solid $color-error;
    }

    .FormField__CheckboxLabel {
      color: $color-error;
      &:before {
        border-color: $color-error;
      }
    }

    .FormField__Checkbox {
      input[type="checkbox"]:checked ~ .FormField__CheckboxLabel {
        &:before {
          border-color: $color-error;
        }
      }
    }
  }
}
