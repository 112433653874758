.SubFormOverview {

	&__Section {
		padding-bottom: 60px;
		border-bottom: 1px solid rgba(25,25,25,.1);
		&:not(:first-child) {
			margin-top: 40px;
		}
	}

	&__SectionHeader {
		position: relative;
	}

	&__SectionItem {
	    padding-top: 30px;
	}

	&__SectionItemElement {
		margin-top: 20px;
	}

	&__SectionHeadline {
		margin-top: 20px;
		margin-bottom: 20px;
		padding-right: 110px;
	}

	&__SectionSubline {

	}

	&__EditBtn {
	    padding-right: 10px;
	    position: absolute;
	    right: 0;
	    top: 50%;
	    transform: translateY(-50%);
	}

	&__Check {
		@include font-p-small();
		margin-left: 28px;
		position: relative;
		list-style-type: none;
		padding-top: 10px;
		padding-bottom: 10px;
		&:before {
			content: '';
			@include inline-svg($icon-check,  $color-black);
			position: absolute;
			width: 24px;
			left: -40px;
		    top: 50%;
		    transform: translateY(-50%);
		}
	}
}
