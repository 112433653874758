@font-face{
	font-family:"Helvetica Neue LT W02_55 Roman";
	src:url("/fonts/285b0922-a3d2-4550-b29d-b4f74a9611e4.eot?#iefix");
	src:url("/fonts/285b0922-a3d2-4550-b29d-b4f74a9611e4.eot?#iefix") format("eot"),url("/fonts/823e6839-53d8-41b9-a5a1-5f05833acd12.woff2") format("woff2"),url("/fonts/92679273-9798-4d27-893b-d53dfd2bb4c7.woff") format("woff"),url("/fonts/e4fb2994-44c3-40fe-bec7-9c4fa593b799.ttf") format("truetype");
}
@font-face{
	font-family:"Helvetica Neue LT W02_56 It";
	src:url("/fonts/cd36001a-de1e-4773-adcf-a6e589f0705a.eot?#iefix");
	src:url("/fonts/cd36001a-de1e-4773-adcf-a6e589f0705a.eot?#iefix") format("eot"),url("/fonts/bcfc49d1-ee2f-4fcc-8322-99607f4a7007.woff2") format("woff2"),url("/fonts/fd783951-0929-49f5-9cdd-5b34008bb362.woff") format("woff"),url("/fonts/035a7d84-08c6-4e91-9dbc-a881c1d76333.ttf") format("truetype");
}
@font-face{
	font-family:"Helvetica Neue LT W02_71488916";
	src:url("/fonts/e276fe3d-01c5-4575-99f1-3fc0780d5417.eot?#iefix");
	src:url("/fonts/e276fe3d-01c5-4575-99f1-3fc0780d5417.eot?#iefix") format("eot"),url("/fonts/a61c00c6-86e7-4fa9-ac6b-476548df9cb8.woff2") format("woff2"),url("/fonts/7fd00069-f615-471e-8089-b0bab013cfd9.woff") format("woff"),url("/fonts/7c110d92-b5c8-416b-b5c6-5f1d6c3a720a.ttf") format("truetype");
}


@font-face {
	font-family:'MessinaSansWebBold';
	src: url('/fonts/MessinaSansWeb-Bold.eot'); /* IE9 Compat Modes */
	src: url('/fonts/MessinaSansWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	     url('/fonts/MessinaSansWeb-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/MessinaSansWeb-Bold.woff') format('woff'); /* Pretty Modern Browsers */
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FB04;
}

@font-face {
	font-family:'MessinaSansWebLight';
	src: url('/fonts/MessinaSansWeb-Light.eot'); /* IE9 Compat Modes */
	src: url('/fonts/MessinaSansWeb-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	     url('/fonts/MessinaSansWeb-Light.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/MessinaSansWeb-Light.woff') format('woff'); /* Pretty Modern Browsers */
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+000D-FB04;
}

// @font-face {
// 	font-family:'MessinaSansWeb';
// 	src: url('/fonts/MessinaSansWeb-Regular.eot'); /* IE9 Compat Modes */
// 	src: url('/fonts/MessinaSansWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 	     url('/fonts/MessinaSansWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
//          url('/fonts/MessinaSansWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
// 	font-weight: 400;
// 	font-style: normal;
// 	font-stretch: normal;
// 	unicode-range: U+000D-FB04;
// }


$font-family-primary: "Helvetica Neue LT W02_55 Roman";
$font-family-primary-bold: "Helvetica Neue LT W02_71488916";

$font-family-secondary: "MessinaSansWebLight";
$font-family-secondary-bold: "MessinaSansWebBold";


@mixin font-h1() {
	font-family: $font-family-secondary;
	font-size: 38px;
	line-height: (51/38);
}

@mixin font-h1-bold() {
	font-family: $font-family-secondary-bold;
	font-size: 38px;
	line-height: (51/38);
}

@mixin font-h2() {
	font-family: $font-family-secondary;
	font-size: 28px;
	line-height: (44/28);
}

@mixin font-h2-bold() {
	font-family: $font-family-secondary-bold;
	font-size: 28px;
	line-height: (44/28);
}

@mixin font-h3() {
	font-family: $font-family-secondary;
	font-size: 24px;
	line-height: (32/24);
}

@mixin font-h3-bold() {
	font-family: $font-family-secondary-bold;
	font-size: 24px;
	line-height: (32/24);
}

@mixin font-h4() {
	font-family: $font-family-secondary;
	font-size: 21px;
	line-height: (28/21);
}

@mixin font-h4-bold() {
	font-family: $font-family-secondary-bold;
	font-size: 21px;
	line-height: (28/21);
}

@mixin font-h5() {
	font-family: $font-family-secondary;
	font-size: 17px;
	line-height: (24/17);
}

@mixin font-h5-bold() {
	font-family: $font-family-secondary-bold;
	font-size: 17px;
	line-height: (24/17);
}


@mixin font-p() {
	font-family: $font-family-primary;
	font-size: 17px;
	line-height: (32/17);
}

@mixin font-p-bold() {
	font-family: $font-family-primary-bold;
	font-size: 17px;
	line-height: (32/17);
}

@mixin font-p-large() {
	font-family: $font-family-primary;
	font-size: 21px;
	line-height: (36/21);
}

@mixin font-p-large-bold() {
	font-family: $font-family-primary-bold;
	font-size: 21px;
	line-height: (36/21);
}

@mixin font-p-large-center() {
	@include font-p-large();
	text-align: center;
}

@mixin font-p-small() {
	font-family: $font-family-primary;
	font-size: 15px;
	line-height: (24/15);
}

@mixin font-p-small-bold() {
	font-size: 15px;
	line-height: (24/15);
	font-family: $font-family-primary-bold;
}
