.TrustbarItem {

	&__LabelHeadline {
		@include font-h5-bold();

		margin-top: 10px;
		text-align: center;
	}

	&__Text {
		margin-top: 8px;
	}

}
