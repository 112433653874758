$headerSmallMd: 200px;
$headerMediumMd: 270px;
$headerLargeMd: 330px;

.Header {
	display: block;
	perspective: 2000px;
	position: relative;
	z-index: 1000;
	max-width: 1920px;
	margin: 0 auto;
	margin-bottom: 10px;

	background-image: linear-gradient(224deg, #CA00A2 0%, #FF6400 100%);
	color: white;

	height: $headerMediumMd;
	// @include breakpoint($screen-xs-max $screen-sm-max) {
	// 	height: 174px;
	// }

	@include breakpoint($screen-md 1920px) {
		height: 28vw;
	}
	@include breakpoint(1920px) {
		height: 540px;
	}

	&:after {
		content: '';
		@include inline-svg($swoosh);
		background-size: 102%;

	    position: absolute;
		bottom: -1px;
		background-position-y: 3px;
		@include breakpoint($screen-md) {
			//bottom: -26px;
		}

	    @include breakpoint(0px 1920px) {
	    	width: 100%;
			height: auto;
		    left: 0;
		    right: 0;
		    padding-top: percentage(344/1918);

	    }
	    @include breakpoint(1920px) {
	    	left: 50%;
    		transform: translateX(-50%);
	    }
	}

	&--large {
		height: 400px;
		@include breakpoint($screen-sm $screen-md) {
			height: $headerLargeMd;
		}

		@include breakpoint($screen-md 1920px) {
			height: 33vw;
		}
		@include breakpoint(1920px) {
			height: 640px;
		}
	}

	&--small {
		height: 140px;
		@include breakpoint($screen-sm $screen-md) {
			height: $headerSmallMd;
		}

		@include breakpoint($screen-md 1920px) {
			height: 21vw;
		}
		@include breakpoint(1920px) {
			height: 400px;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 50%;
			opacity: 0.1;
			background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.00) 100%);
		}
	}

	&__FadeLeft {
		@include breakpoint(1921px) {
		    position: absolute;
		    bottom: 0;
		    top: 0;
		    left: 0;
		    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		    width: 500px;
		    z-index: 100;
		}
	}

	&__FadeRight {
		@include breakpoint(1921px) {
		    position: absolute;
		    bottom: 0;
		    top: 0;
		    right: 0;
		    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
		    width: 500px;
		    z-index: 100;
		}
	}

	&__Container {
		position: relative;
		z-index: 1000;
		line-height: 60px;
		@include container-width();
		margin-top: 19px;
	}






	&__BackgroundImageContainer {
		width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    overflow: hidden;
	}
	&__BackgroundImage {
		width: 100%;
		height: 100%;
	}



}
