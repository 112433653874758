.Headline {
	&__H1 {
		@include font-h2();
		&--bold {
			@include font-h2-bold();
		}
		@include breakpoint($screen-md) {
			@include font-h1();
			&--bold {
				@include font-h1-bold();
			}
		}
	}

	&__H2 {
		@include font-h3();
		&--bold {
			@include font-h3-bold();
		}
		@include breakpoint($screen-md) {
			@include font-h2();
			&--bold {
				@include font-h2-bold();
			}
		}
	}

	&__H3 {
		@include font-h4();
		&--bold {
			@include font-h4-bold();
		}
		@include breakpoint($screen-md) {
			@include font-h3();
			&--bold {
				@include font-h3-bold();
			}
		}
	}

	&__H4 {
		@include font-h4();
		&--bold {
			@include font-h4-bold();
		}
	}

	&__H5 {
		@include font-h5();
		&--bold {
			@include font-h5-bold();
		}
	}

	&--center {
		@include breakpoint($screen-md) {
			text-align: center;
		}
	}

}
