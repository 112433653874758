.Dimmer {
	position: fixed;
	z-index: 1400;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $color-black;
	opacity: 0;
	transition: .3s opacity;
	transform: scale(0);
	&.visible {
		opacity: .3;
		transform: scale(1);
	}

	&.out {
    	animation: quickScaleDown 0s .3s linear forwards;
    }
}


@keyframes quickScaleDown {
  0% {
    transform:scale(1);
  }
  99.9% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
