.JobListItem {
	&__Meta {
		@include font-p-small();
	}


	&__Category {
		margin-bottom: 5px;
		@include breakpoint($screen-sm) {
			margin-bottom: 15px;
		}
	}

	&__CategoryTitle {
		@include font-p-small-bold();
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}

	&__CategoryValue {
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}


	&__StartsAt {
		margin-bottom: 5px;
		@include breakpoint($screen-sm) {
			margin-bottom: 15px;
		}
	}

	&__StartsAtTitle {
		@include font-p-small-bold();
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}

	&__StartsAtValue {
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}



	&__Location {
		margin-bottom: 20px;
		@include breakpoint($screen-sm) {
			margin-bottom: 0;
		}
	}

	&__LocationTitle {
		@include font-p-small-bold();
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}

	&__LocationValue {
		display: inline-block;
		@include breakpoint($screen-sm) {
			display: block;
		}
	}

	&__Text {
		margin-top: 15px;
		@include font-p-small();
		@include breakpoint($screen-md) {
			@include font-p();
		}
	}

	&__CTA {
		margin-top: 30px;
		@include breakpoint($screen-md) {
			margin-top: 20px;
		}
	}
}
