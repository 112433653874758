.Icon {

	display: inline-block;

	&--search {
		@include inline-svg($icon-magnifier);
	}

	&--closeCircle {
		@include inline-svg($icon-close-circle);
	}

	&--mail {
		@include inline-svg($icon-mail);
	}

	&--phone {
		@include inline-svg($icon-phone);
	}

	&--phoneSmall {
		@include inline-svg($icon-phone);
		width: 20px;
		height: 20px;
	}

	&--phoneWhite {
		@include inline-svg($icon-phone-white);
	}

	&--phoneWhiteSmall {
		@include inline-svg($icon-phone-white);
		width: 20px;
		height: 20px;
	}

	&--energy {
		@include inline-svg($icon-energy, #FFFFFF);
		width: 20px;
		height: 20px;
	}


	&--personOneBlack {
		@include inline-svg($icon-person-one, #000000);
	}

	&--info {
		@include inline-svg($icon-info, $color-black);
	    display: inline-block;
	    vertical-align: middle;
	    margin-left: 6px;
	    margin-right: 6px;
	    margin-bottom: 3px;
	}

	&--downloadBlack {
		@include inline-svg($icon-download, $color-black);
	}

	&--personTwo {
		@include inline-svg($icon-person-two, #FFFFFF);
	}

	&--personTwoSmall {
		@include inline-svg($icon-person-two, #FFFFFF);
		width: 20px;
		height: 20px;
	}

	&--print {
		@include inline-svg($icon-print, $color-black);
	}

	&--sign {
		@include inline-svg($icon-sign, $color-black);
	}

	&--download {
		@include inline-svg($icon-download, #FFFFFF);
	}

	&--bottom {
		vertical-align: middle;
	}

	&--small {
		width: 20px;
		height: 20px;
	}
}


