.Page {

	overflow-x: hidden;

	&__OverlayWrapper {
		position: absolute;
		z-index: 1500;
		top: 0;
		left: 0;
		right: 0;
		perspective: 1000px;
		height: 300px;
		pointer-events: none;
	}

	&__Breadcrumbs {
		// padding: 0 0 50px 0;
	}



	&__MobileNav {
		position: absolute;
		z-index: 1000;
	    top: 25px;

	    left: $body-padding-xs;
		right: $body-padding-xs;
		@include breakpoint($screen-sm) {
	  		left: $body-padding-sm;
	  		right: $body-padding-sm;
	  	}

	    transition: 0.25s all;
	    transform: rotate3d(1,1,0,-15deg);
	    opacity: 0;
	    pointer-events: none;
	    transform-origin: 50% -50%;

	    visibility: hidden;
	    &--visible {
	    	transform: none;
	    	opacity: 1;
	    	pointer-events: all;
	    	visibility: visible;
	    }
	}


	&__SearchInner {
		max-width: calc(1280px + #{$body-padding-md} * 2);
		margin: 0 auto;
		margin-top: 34px;
		padding: 0 $body-padding-xs;
		@include breakpoint($screen-sm) {
			padding: 0 $body-padding-sm;
		}
		@include breakpoint($screen-md) {
			padding: 0 $body-padding-md;
		}
	}

	&__Search {
		position: absolute;
		z-index: 1100;
	    top: 0;
	    left: 0;
	    right: 0;
	    height: 120px;
	    transition: 0.3s all;
	    //transform: translateY(0px) rotateX(-30deg);
	    transform: translateY(-54px) rotateX(-42deg);
	    opacity: 0;
	    pointer-events: none;
	    background-color: white;
	    transform-origin: 50% -50px;
	    visibility: hidden;

	    will-change: transform;

	    @include bg-box-shadow();

	    &--visible {
	    	visibility: visible;
	    	pointer-events: all;
	    	opacity: 1;
	    	transform: translateY(0) rotateX(0);
	    }
	}

	&__MainNav {
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__MainNavContainer {
		position: relative;
		width: 100%;
		z-index: 1200;
		line-height: 60px;
		@include container-width();
		margin-top: 19px;
	}
}
