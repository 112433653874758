.SubForm {
  border-bottom: 1px solid rgba(25, 25, 25, 0.2);

  .hiddenForm {
    height: 0;
    visibility: hidden;
    opacity: 0;
  }

  &:last-child {
    &:not(.active) {
      // margin-bottom: 100vh;
    }
  }

  &.active {
    border: none;
    transition: 0.2s padding;
    &:not(:first-child) {
      padding-top: 50px;
    }
    .SubForm__HeaderScale {
      opacity: 1;
      transform: none;
    }
  }

  &:not(.active) {
    .SubForm__Header {
      padding-right: 110px;
    }
  }

  &.completed {
    .SubForm__HeaderScale {
      opacity: 1;
    }
    .SubForm__HeaderIndex {
      &:before {
        content: "";
        @include inline-svg($icon-check, $color-black);
        width: 25px;
        height: 30px;
        position: absolute;
        left: -11px;
        top: -1px;
      }
    }
  }

  &__Header {
    padding-top: 40px;
    padding-bottom: 40px;
    color: $color-black;
    padding-left: 2px;
    position: relative;
  }

  &__HeaderScale {
    transform: scale(0.7);
    transform-origin: left;
    position: relative;
    opacity: 0.2;
    transition: 0.3s all;
  }

  &__HeaderIndex {
    vertical-align: middle;
    font-size: 24px;
    line-height: 1.33333;
    position: relative;
    margin-left: 17px;
    margin-right: 44px;

    &:before {
      content: attr(data-index);
    }

    &:after {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      border: 2px solid #191919;
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__HeaderTitle {
    vertical-align: middle;
    //@include font-h1-bold();
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 70%;
    @include breakpoint($screen-sm) {
      width: 100%;
    }
  }

  &__HeaderEditButton {
    position: absolute;
    right: 10px;
    top: 38px;
  }

  &__SubmitButtons {
    margin-top: 40px;
    padding-bottom: 100px;
    text-align: right;
    @include breakpoint($screen-sm) {
      padding-right: 10px;
    }
  }

  &__SubmitButton {
    margin-top: 20px;
    @include breakpoint($screen-sm) {
      margin-left: 30px;
    }
  }
}
