.Map {
	&__MapContainer {
		width: 100%;
		height: 300px;
		margin-top: 30px;
		@include breakpoint($screen-md) {
			margin-top: 40px;
			height: 500px;
		}
	}

	&__InfoBox {
		position: absolute;
		transform: translateX(-50%);
		bottom: 80px;
		@include bg-box-shadow();
		background-color: white;
		padding: 25px 30px;
		width: 320px;
		max-width: 80vw;

		&:before{
			content: " ";
		    width: 0;
		    height: 0;
		    border-left: 10px solid transparent;
		    border-right: 10px solid transparent;
		    border-top: 10px solid #fff;
		    position: absolute;
		    bottom: -10px;
		    left: 50%;
		    transform: translateX(-50%);
		}
	}

	&__MarkerDetailsItem {
		margin-top: 10px;
	}

	&__CloseBtn {
		@include inline-svg($icon-close,  $color-black);
		position: absolute;
		right: 10px;
		top: 8px;
		width: 20px;
	}

	&__Text {
		margin-top: 10px;
		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}
}


.infoBox > img {
	display: none;
}
