.SubFormSection {

	border-bottom: 1px solid rgba(25,25,25,.2);
	padding-top: 50px;
    padding-bottom: 50px;

    &:first-child {
    	padding-top: 30px;
    }

    &:last-child {
    	//margin-bottom: 120px;
    	border: none;
    }



	&__Headline {
		@include font-h3-bold();
	}

	&__Subline {
		@include font-p();
		margin-top: 10px;
		margin-bottom: 20px;
	}

	&__Item {
		margin-top: 40px;
	}

	a {
		color: $color-black;
		background-image: linear-gradient(224deg, $color-black 0%, $color-black 100%);
		background-position: 0 1.15em;
		background-size: 100% 3px;
		background-repeat: repeat-x;

		&:hover {
			background-image: linear-gradient(224deg, #CA00A2 0%, #FF6400 100%);
		}
	}
}
