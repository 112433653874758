.CO2Savings {
	margin-top: 60px;
	&__TextImage {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		flex-wrap: wrap;
		@include breakpoint($screen-sm) {
			flex-wrap: nowrap;
		}
	}

	&__Image {
		margin-right: 50px;
	}

	&__Text {
		max-width: 350px;
		margin-top: 30px;
		@include breakpoint($screen-sm) {
			margin-top: 0;
		}
	}
}
