.MobileSearch {
	&__SubmitBtn {
		float: right;
	}

	&__Input {
		width: 100%;
		height: 48px;


		border: 1px solid rgba($color-black, 0.2);
		border-radius: 5px;
		font-family: $font-family-primary-bold;
		font-size: 17px;
		color: $color-black;
		padding: 0 10px;

		-webkit-appearance: none;
		-webkit-border-radius:5px;

		&::placeholder {
			color: rgba($color-black, 0.2);
		}
	}

	&__InputWrapper {
		overflow: hidden;
		padding-right: 22px;
		margin-bottom: 10px;
		@include breakpoint($screen-sm-min) {
			padding-right: 34px;
			margin-bottom: 0;
		}
	}
}

