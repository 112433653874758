.MainNav {
	perspective: 2000px;
	margin: 0;
	padding: 0;

	display: inline-block;

	font-family: $font-family-primary-bold;
	font-size: 17px;


	li {
		list-style-type: none;
	}

	&__Level1Container {
		@include clearfix();
		position: relative;
		display: inline-block;
		margin: 0;
	}


	&__NavItem {
		&--level-1 {
			float: left;
		}
	}

	&__NavItemLink {
		&--level-1 {
			padding: 20px 12px;
			@include breakpoint(1290px) {
				padding: 20px;
			}

			&:hover {
				.MainNav__NavItemLabel:after {
					opacity: 1;
				}
			}
		}
	}

	&__NavItemLabel {
		&--level-1 {
			color: white;
			position: relative;
			display: inline-block;
			&:hover, &:active, &:focus {
				color: white;
			}

			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 12px;
				height: 2px;
				width: 100%;
				background-color: white;
				@include cta-shadow-white();
				transition: 0.2s opacity;
				opacity: 0;

			}

		}
	}

	&__SubNav {
		margin: 0;
		padding: 0;
		display: none;
		position: absolute;
		//background-color: white;
		opacity: 0;
		left: 50%;
    	top: 11px;
    	//width: 206px;
    	transform: translate(-50%, 0);

    	transition: 0.3s all;

    	padding-left: 0;
    	padding-right: 0;
    	padding-top: 0;
    	padding-bottom: 0;


    	white-space: nowrap;

    	@include font-p();
    	text-align: center;





		&.animateInFromLeft {
			animation: AnimateInFromLeft 0.2s;
			animation-fill-mode: forwards;
			opacity: 1;
			display: block;
		}

		&.animateInFromRight {
			animation: AnimateInFromRight 0.2s;
			animation-fill-mode: forwards;
			opacity: 1;
			display: block;
		}

		&.animateOutToLeft {
			animation: AnimateOutToLeft 0.2s;
			animation-fill-mode: forwards;
		}

		&.animateOutToRight {
			animation: AnimateOutToRight 0.2s;
			animation-fill-mode: forwards;
		}
	}

	&__NavItem {
		&--level-2 {

		}
	}

	&__NavItemLink {
		&--level-2 {
			padding: 0 20px;
			display: block;
			line-height: 50px;
			transition: 0.1s background-size;
			background-position: 50% 50%;
		    background-size: 100% 0%;
		    background-repeat: no-repeat;
			&:hover {
				background-image: linear-gradient(45deg, #FF00B5 0%, #CA00A2 100%);
				color: white;
				background-size: 100% 100%;
			}
		}
	}


	&__SubNavBoxContainer {
		position: absolute;
		transform: rotateX(-15deg);
		transition: 0.2s all;

		&.visible {
			transform: none;

			.MainNav__SubNavBox {
				opacity: 1;
				visibility: visible;

			}
		}
	}

	&__SubNavBox {
		//overflow: hidden;
		position: absolute;
		top: -25px;
		opacity: 0;
		visibility: hidden;

		transform-origin: 50% -50px;
		transition: 0.2s all;

		&.noDropDownTransition {
			transition: none;
		}

		&:hover {
    		opacity: 1;
    	}

		a {
			color: $color-black;
		}

	}

	&__SubNavBoxInner {

		@include bg-box-shadow();
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    top: 11px;
	    background: white;
	    width: 100%;
	    height: 100%;

	    &:after {
	    	content: '';
	    	height: 11px;
			@include inline-svg($icon-menu-arrow-up);
			position: absolute;
		    left: 50%;
		    transform: translateX(-50%);
		    top: -11px;
	    }
	}

	&__Secondary {
		display: inline-block;
	}

}


@keyframes AnimateInFromLeft {
	0% {
		display: block;
		transform: translate(-50%, 0);
	}
	100% {
		transform: translate(-50%, 0);
	}
}

@keyframes AnimateOutToLeft {
	0% {
		transform: translate(-50%, 0);
	}
	99% {
		transform: translate(-50%, 0);
	}
	100% {
		display: none;
	}
}

@keyframes AnimateInFromRight {
	0% {
		display: block;
		transform: translate(-50%, 0);
	}
	100% {
		transform: translate(-50%, 0);
	}
}

@keyframes AnimateOutToRight {
	0% {
		transform: translate(-50%, 0);
	}
	99% {
		transform: translate(-50%, 0);
	}
	100% {
		display: none;
	}
}
