.SecondaryCTA {
	position: relative;
	padding: 5px 10px 5px 0;
	font-family: $font-family-primary-bold;
	font-size: 17px;
	line-height: 30px;
	margin-right: 30px;
	display: inline-block;

	transition: .3s padding;

	&:after, &:before {
		content: '';

		position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    margin-left: 10px;
		transition: .35s all;
		right: -30px;
	}

	&:after {
		opacity: 1;
		@include inline-svg($icon-cta-arrow);
		width: 30px;
    	height: 30px;
	}

	&:before {
		opacity: 0;
		@include inline-svg($icon-cta-arrow-color);
		width: 30px;
    	height: 30px;
	}



	color: $color-black;
	&:focus {
		color: $color-black;
	}
	&:hover {
		// padding-right: 20px;
		color: $color-black;
		&:after {
			right: -40px;
			width: 30px;
    		height: 30px;
    		opacity: 0;
		}
		&:before {
			right: -40px;
			width: 30px;
    		height: 30px;
    		opacity: 1;
		}
	}

}
