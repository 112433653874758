.Search {
	color: $color-black;

	&__CloseBtn {
		@include inline-svg($icon-close-circle);
		cursor: pointer;
		opacity: 0.2;
    	display: inline-block;
	    vertical-align: middle;
	    margin-left: 20px;
		&:hover {
			opacity: 0.8;
		}
	}

	&__Input {
		font-family: $font-family-secondary;
		font-size: 28px;
		line-height: 41px;
		width: 100%;
		margin-top: 6px;
	}

	&__SubmitCloseContainer {
		text-align: right;
	}
}
