.Modal {
	transform: scale(0);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: scroll;
	z-index: 2000;
	perspective: 2000px;

	&.out {
		animation: quickScaleDown 0s 0.3s linear forwards;
	}

	&.active {
		transform: scale(1);
	}

	&__Background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__Box {
		position: absolute;
		background-color: white;
		// top: $headerMediumMd;
		top: 10vh;
		left: 50%;
		transform: translate(-50%, 0) rotateX(-23deg) rotateY(-10deg);
		transform-origin: 50% -50%;
		transition: 0.3s transform;
		opacity: 0;
		transition: 0.3s all;
		width: calc(100% - #{$body-padding-sm} * 2);
		@include bg-box-shadow();

		@include breakpoint(0 $screen-xs-max) {
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			transform: translate(0, 0) rotateX(-45deg) rotateY(-10deg);
		}

		&--small {
			@include breakpoint($screen-sm) {
				width: 60%;
				max-width: 800px;
			}
		}
	}

	// @include breakpoint($screen-sm) {
	// 	&--small {
	// 		.Modal__Box {
	// 			top: $headerSmallMd;
	// 		}
	// 	}

	// 	&--large {
	// 		.Modal__Box {
	// 			top: $headerLargeMd;
	// 		}
	// 	}
	// }

	&.visible {
		.Modal__Box {
			opacity: 1;
			transform: translate(-50%, 0);
			@include breakpoint(0 $screen-xs-max) {
				transform: translate(0, 0);
			}
		}
	}

	&__Content {
		@include breakpoint(0 $screen-xs-max) {
			padding: 10px $body-padding-xs 70px $body-padding-xs;

			position: absolute;
			left: 0;
			right: 0;
			bottom: 90px;
			top: 0;

			overflow: scroll;
			// -webkit-overflow-scrolling: touch;
			// background-color: red;
		}
	}

	&__CloseBtnContainer {
		text-align: right;
		padding-top: 15px;
		padding-right: 20px;
	}

	&__CloseBtn {
		@include font-p-small-bold();
		line-height: 30px;
		display: inline-block;
		opacity: 0.2;
		transition: 0.2s opacity;
		outline: none;

		position: absolute;
		right: 15px;
		z-index: 10;

		&:after {
			content: "";
			@include inline-svg($icon-close-circle);
			display: inline-block;
			line-height: 30px;
			vertical-align: bottom;
			margin-left: 6px;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__Headline {
		margin-bottom: 20px;
	}
}

@keyframes quickScaleDown {
	0% {
		transform: scale(1);
	}
	99.9% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
