.Job {

	margin-top: 40px;
	// margin-bottom: 40px;

	@include breakpoint($screen-sm) {
		margin-top: 50px;
		// margin-bottom: 50px;
	}

	@include breakpoint($screen-md) {
		margin-top: 60px;
		// margin-bottom: 60px;
	}

	&__Headline {
		margin-top: 20px;

		@include breakpoint($screen-sm) {
			text-align: center;
		}

		@include breakpoint($screen-md) {
			margin-top: 30px;
		}
	}

	&__Text {
		margin-top: 15px;

		@include breakpoint($screen-sm) {
			text-align: center;
		}

		@include breakpoint($screen-md) {
			margin-top: 20px;
		}

	}

	&__MetaItems {
		margin-top: 20px;

		@include breakpoint($screen-sm) {
			margin-top: 30px;
		}
	}

	&__MetaItem {
		text-align: left;
		margin-top: 10px;

		@include breakpoint($screen-sm) {
			text-align: center;
			margin-top: 0;
		}

	}

	&__MetaItemHeadline {
		@include font-p-small-bold();

		display: inline;

		@include breakpoint($screen-sm) {
			display: block;
		}
	}

	&__MetaItemLabel {
		@include font-p-small();

		display: inline;

		@include breakpoint($screen-sm) {
			display: block;
		}
	}

}
