.Background {
	position: relative;
	overflow: hidden;

	&__Container {
		&:before {
			content: '';
			background-image: linear-gradient(45deg, #ff00b5 0%, #ca00a2 100%);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			// z-index: -1;
		}

		&:after {
			content: '';
			position: absolute;
			@include inline-svg($background-swoosh-pink);
			transform: rotateZ(2deg);
			bottom: -58%;
			right: -327px;
			background-size: 75%;
		}
	}

	&--orange {
		.Background__Container {
			&:before {
				background-image: linear-gradient(225deg, #ff9700 0%, #ff6400 100%);
			}
			&:after {
				@include inline-svg($background-swoosh-orange);
				background-size: 75%;
				bottom: -72%;
				left: -390px;

				@include breakpoint($screen-md) {
				}
			}
		}
	}

	&--pinkLarge {
		.Background__Container {
			&:after {
				transform: rotateZ(2deg);
				bottom: -45%;
				right: -9%;
				background-size: 100%;
			}
		}
	}

	&--orangeLarge {
		.Background__Container {
			&:after {
				transform: rotateZ(-2deg);
				top: -1181px;
				left: -264px;
				background-size: 100%;

				@include breakpoint($screen-md) {
					left: -230px;
					top: -1220px;
				}
				@include breakpoint($screen-lg) {
					left: 86px;
					top: -1268px;
				}
			}
		}
	}
}
