.List {

	&__Header {
		margin-bottom: 40px;
		@include breakpoint($screen-md) {
			margin-bottom: 30px;
		}
		@include breakpoint($screen-lg) {
			margin-bottom: 40px;
		}
	}

	&__Headline {

	}

	&__Text {
		margin-top: 10px;
		@include breakpoint($screen-lg) {
			margin-top: 15px;
		}
	}

	&__Items {
		margin-left: -$body-padding-xs;
		margin-right: -$body-padding-xs;
		@include breakpoint($screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__Item {
		padding: 40px $body-padding-xs;
		@include breakpoint($screen-sm) {
			padding: 40px 30px;
		}

		border-bottom: 1px solid rgba(25, 25, 25, 0.2);

		&:first-child {
			border-top: 1px solid rgba(25, 25, 25, 0.2);
		}
	}

	&__LoadMoreBtn {
		text-align: center;
		margin-top: 60px;
		@include breakpoint($screen-lg) {
			margin-top: 100px;
		}
	}

	&__FallbackText {
		display: none;
	}
}
