.SearchPage {
  margin-top: 40px;

  &__Headline {
    @include font-h3-bold();

    @include breakpoint($screen-md) {
      @include font-h2-bold();

      text-align: center;
    }
  }

  &__Items {

    margin-top: 14px;

    @include breakpoint($screen-sm) {
      margin-top: 40px;
    }

    @include breakpoint($screen-md) {
      margin-top: 60px;
    }

  }

  &__Item {
		margin: 14px $body-padding-xs / ($body-padding-xs * 0 + 1) * (-1vw) 0;
		padding: 0 $body-padding-xs / ($body-padding-xs * 0 + 1) * (1vw);
    border-top: 1px solid rgba(25, 25, 25, .2);

		@include breakpoint($screen-sm) {
      margin: 14px 0 0 0;
			padding: 0;
    }

    &:last-child {
      margin-bottom: 60px;
      border-bottom: 1px solid rgba(25, 25, 25, .2);
    }

  }

  &__Search {
    margin-top: 20px;

    @include breakpoint($screen-md) {
  		margin-top: 30px;
  	}
  }

}
