.PricingCalculatorResults {
	@include clearfix();

	&__ContainerRateName {
		float: left;
		width: 100%;
		margin-bottom: 15px;

		@include breakpoint($screen-lg) {
			margin-bottom: 0;
			width: 35%;
		}
	}

	&__RateNameLabel {
		@include font-h3();
		float: left;
		width: 100%;

		@include breakpoint($screen-sm) {
			@include font-h2();
			width: auto;
			margin-right: 15px;
		}

		@include breakpoint($screen-lg) {
			width: 100%;
		}
	}

	&__RateName {
		@include font-h3-bold();
		float: left;
		width: 100%;

		@include breakpoint($screen-sm) {
			@include font-h2-bold();
			width: auto;
		}

		@include breakpoint($screen-lg) {
			width: 100%;
		}
	}


	&__Container {
		@include clearfix;
		margin-bottom: 20px;

		@include breakpoint($screen-sm) {
			margin-bottom: 25px;
		}

		@include breakpoint($screen-lg) {
			margin-top: 10px;
			float: right;
		}
	}


	&__ContainerKwh {
		float: left;
		width: 100%;
		margin-bottom: 11px;

		@include breakpoint($screen-sm) {
			width: 50%;
			margin-bottom: 0;
		}

		@include breakpoint($screen-lg) {
			width: auto;
			margin-right: 60px;
		}
	}

	&__KwhLabel {
		@include font-p-small();
		float: left;
		width: 50%;

		@include breakpoint($screen-sm) {
			width: 100%;
		}

		@include breakpoint($screen-lg) {
			@include font-p-small();
			float: none;
			width: auto;
			margin-right: 10px;
		}
	}

	&__Kwh {
		@include font-h5-bold();
		float: left;
		width: 50%;

		@include breakpoint($screen-sm) {
			@include font-h3-bold();
			width: 100%;
		}

		@include breakpoint($screen-lg) {
			@include font-h4-bold();
			float: none;
			width: auto;
		}
	}


	&__ContainerAddress {
		float: left;
		width: 100%;

		@include breakpoint($screen-sm) {
			width: 50%;
		}

		@include breakpoint($screen-lg) {
			width: auto;
		}
	}

	&__AddressLabel {
		@include font-p-small();
		float: left;
		width: 50%;

		@include breakpoint($screen-sm) {
			width: 100%;
		}

		@include breakpoint($screen-lg) {
			@include font-p-small();
			float: none;
			width: auto;
			margin-right: 10px;
		}
	}

	&__Address {
		@include font-h5-bold();
		float: left;
		width: 50%;

		@include breakpoint($screen-sm) {
			@include font-h3-bold();
			width: 100%;
		}

		@include breakpoint($screen-lg) {
			@include font-h4-bold();
			float: none;
			width: auto;

		}
	}

	&__ContainerChangeData {
		float: left;
		width: 100%;

		@include breakpoint($screen-lg) {
			float: right;
			text-align: right;
		}
	}
}
