.EventListItem {

	&__Meta {
		overflow: auto;
	}

	&__Headline {
		margin-top: 20px;
		@include breakpoint($screen-md) {
			margin-top: 0;
		}
	}

	&__Subline {
		@include font-p-small-bold();
		margin-top: 5px;
		@include breakpoint($screen-lg) {
			margin-top: 10px;
		}
	}

	&__Text {
		margin-top: 15px;
		@include font-p-small();
		@include breakpoint($screen-md) {
			@include font-p();
		}
	}

	&__Image {
		margin-top: 20px;
		@include breakpoint($screen-md) {
			margin-top: 0;
			width: percentage(3/4);
			float: right;
		}
		@include breakpoint($screen-lg) {
		}
	}

	&__Day {
		@include font-p-small();
		display: inline-block;
		@include breakpoint($screen-md) {
			text-align: center;
			display: block;
			@include font-h1-bold();
		}
	}

	&__Month {
		@include font-p-small();
		display: inline-block;
		@include breakpoint($screen-md) {
			@include font-h4();
			text-align: center;
			display: block;
		}
	}
	&__Year {
		@include font-p-small();
		display: inline-block;
		@include breakpoint($screen-md) {
			text-align: center;
			@include font-h4();
			display: block;
		}
	}

	&__Location {
		@include font-p-small();
		float: right;
		display: inline-block;
		@include breakpoint($screen-md) {
			float: none;
			margin-top: 30px;
			text-align: center;
			display: block;
		}

		&:before {
			content: '';
			@include inline-svg($icon-location);
			width: 25px;
		    height: 25px;
		    display: inline-block;
		    vertical-align: bottom;
		    margin-right: 7px;
		    margin-left: -3px;
		}
	}

	&__CTA {
		margin-top: 30px;
		@include breakpoint($screen-md) {
			margin-top: 20px;
		}
	}
}
