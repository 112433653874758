.TeaserItem {
	position: relative;
	&__Label {
		text-align: center;
		@include font-h4();
		margin-top: 20px;
		margin-bottom: 15px;

		padding: 0 5px;
		@include breakpoint($screen-md) {
			@include font-h2();
			margin-top: 40px;
			margin-bottom: 20px;
		}

		@include breakpoint($screen-lg) {
			padding: 0;
		}

		color: $color-black;
		&:hover,
		&:focus,
		&:active {
			color: $color-black;
		}
	}

	&__CTA {
		text-align: center;
	}

	&--onImage {
		.TeaserItem__Meta {
			position: absolute;
		    bottom: 0;
		    left: 0;
		    width: 100%;
		    padding: 0 0 5% 5%;
		}
		.TeaserItem__Label {
			text-align: left;
			color: black;
		}

		.TeaserItem__CTA {
			text-align: left;
		}
	}

	&--large {

		@include breakpoint($screen-sm $screen-lg) {
			margin-bottom: 20px;
		}


		.TeaserItem__Label {

			@include breakpoint($screen-sm) {
				@include font-h2();
			}

			@include breakpoint($screen-lg) {
				@include font-h1();
			}
		}
	}

	&__ImageContainer {
		overflow: hidden;
	}

	&__Image {
		transition: .4s transform ease-in-out;

	}

	&:hover {
		.TeaserItem__Image {
			transform: scale(1.1);
		}
	}

}
