@mixin bg-box-shadow() {
	box-shadow: 0 7px 25px 0 rgba(0,0,0,0.15);
}

@mixin cta-shadow-white() {
	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
}


/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
///
/// more info: https://css-tricks.com/snippets/sass/strip-unit-function/
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
