.FAQ {

	&__Headline {
		margin-top: 40px;
		// margin-bottom: 40px;

		@include breakpoint($screen-sm) {
			margin-top: 50px;
		// margin-bottom: 50px;
		}

		@include breakpoint($screen-md) {
			margin-top: 60px;
		// margin-bottom: 60px;
		}
	}

	&__Text {
		margin-top: 20px;
		@include breakpoint($screen-md) {
			margin-top: 30px;
		}
	}

	&__CTAContainer {
	}

	&__BackCTA {
		position: relative;
		padding: 5px 0 5px 10px;
		font-family: $font-family-primary-bold;
		font-size: 17px;
		line-height: 30px;
		margin-left: 30px;
		display: inline-block;

		transition: .3s padding;

		&:after, &:before {
			content: '';

			position: absolute;
		    top: 50%;
		    transform: translateY(-50%) rotateZ(180deg);
		    margin-right: 10px;
			transition: .35s all;
			left: -30px;
		}

		&:after {
			opacity: 1;
			@include inline-svg($icon-cta-arrow);
			width: 30px;
	    	height: 30px;
		}

		&:before {
			opacity: 0;
			@include inline-svg($icon-cta-arrow-color);
			width: 30px;
	    	height: 30px;
		}



		color: $color-black;
		&:focus {
			color: $color-black;
		}
		&:hover {
			// padding-right: 20px;
			color: $color-black;
			&:after {
				left: -40px;
				width: 30px;
	    		height: 30px;
	    		opacity: 0;
			}
			&:before {
				left: -40px;
				width: 30px;
	    		height: 30px;
	    		opacity: 1;
			}
		}
	}

}
