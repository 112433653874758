.ApplicationResultScreen {

	&__Headline {

	}

	&__Subline {

	}

	&__Section {
		margin-top: 55px;
	}

	&__SubmitBtn {
		margin-top: 50px;
	}

	&__InstructionItems {
		margin-top: 30px;
	}

	&__InstructionItem {
		text-align: center;
		margin-bottom: 40px;
		@include breakpoint($screen-md) {
			margin-bottom: 0;
		}
	}

	&__InstructionItemLabelText {
		margin-left: 10px;
	}
}
