.ApplicationHeader {
	padding: 20px 30px;
	&__Header {
		@include breakpoint($screen-md) {
			float: left;
		}
	}

	&__Headline {
		margin-bottom: 15px;
		@include breakpoint($screen-sm) {
			max-width: 300px;
			margin-bottom: 0;
			display: inline-block;
		}
	}

	&__Facts {
		display: inline-block;
		margin-right: 20px;
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 0;
			margin-top: 5px;
			float: right;
			margin-right: 20px;
		}
		// @include breakpoint($screen-lg) {
  //   		margin-top: 36px;
		// }
	}

	&__FactsItem {
		// margin-top: 10px;
		@include breakpoint($screen-md) {
			margin-top: 0;
			float: left;
	    	margin-left: 20px;
	    }
    	> * {
    		// display: block;
    		// @include breakpoint($screen-lg) {
    		// 	display: inline;
    		// }
    	}
	}

	&__FactsItemLabel {
		@include font-p();
	}

	&__FactsItemValue {
		@include font-p-bold();
	}


	&__Sublines {
		margin-top: 10px;
		vertical-align: bottom;
		@include breakpoint($screen-sm) {
			margin-left: 20px;
			margin-top: 0;
			display: inline-block;
		}
	}

	&__Subline {
		@include breakpoint($screen-lg) {
			margin-top: 5px;
		}
	}

	&--combined {
		.ApplicationHeader__Header {
			width: 100%;
		}

		.ApplicationHeader__Headline {
			margin-bottom: 15px;

			@include breakpoint(1350px) {
				margin-bottom: 0;
				float: left;
			}
		}

		.ApplicationHeader__Sublines {
			display: block;
			margin-left: 0;
			@include breakpoint($screen-md) {
				display: inline-block;
			}
			@include breakpoint(1350px) {
				float: right;
			}
		}

		.ApplicationHeader__Subline {
    		// overflow: auto;
    		@include breakpoint($screen-md) {
				// float: left;
				display: inline-block;
	    		&:first-child {
	    			margin-right: 40px;
	    		}
	    	}
		}

		.ApplicationHeader__Facts {
			float: none;
			overflow: auto;
			display: block;
			margin-top: 0;
			margin-bottom: 15px;
			margin-right: 0;

    		@include breakpoint($screen-md) {
    			margin-top: 0;
				margin-bottom: 0;

    		}

		}

		.ApplicationHeader__FactsItem {
			margin-left: 0;
			margin-right: 20px;
			float: none;
			display: inline-block;

			@include breakpoint($screen-lg) {
				float: left;
				display: block;
			}


			> * {
	    		// display: block;
	    		// @include breakpoint($screen-lg) {
	    		// 	display: block;
	    		// }
	    	}
		}
	}

}
