.MobileNav {
    background-color: white;
    @include bg-box-shadow();
    color: $color-black;



    &__Menu {
    	clear: both;
    }

    &__List {
    	margin: 0;
    	padding: 0;
    }

    &__NavItem {
    	list-style: none;


    	&--level-1 {
    		border-bottom: 1px solid lightgrey;
    		padding: 15px 0;
    		@include font-p-large();
    	}

    	&--level-2 {
    		font-size: 17px;
    	}

	    &.active {
		    .MobileNav__NavItemLabel {
		    	&--level-1 {
		    		//@include font-p-large-bold();
		    	}

		    	&--level-2 {

		    	}
		    }

		    .MobileNav__NavItemLink {
				&--level-1 {
					&:after {
						transform: rotateZ(-180deg) translateY(50%);
					}
		    	}
		    }

		    .MobileNav__NavItemLabel {
				&--level-1 {
		    		&:after {
		    			content: '';
		    			width: 100%;
		    			height: 2px;
		    			background-color: $color-black;
		    			position: absolute;
					    left: 0;
					    bottom: -8px;
					    @include bg-box-shadow();
						box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
		    		}
		    	}
		    }

		}
    }



    &__NavItemLink {
    	color: $color-black;
    	position: relative;
    	padding-left: 30px;
    	padding-right: 70px;
    	display: block;
    	//line-height: 47px;

    	&:hover, &:active, &:focus {
    		color: $color-black;
    	}

    	&--level-1 {
    		display: block;

    		&:after {
    			content: '';
    			@include inline-svg($icon-arrow-down);
    			width: 20px;
    			height: 20px;
    			position: absolute;


			    right: 33px;
			    transition: 0.2s transform;
			    transform: rotateZ(0) translateY(-50%);
			    top: 50%;
    		}
    	}

    	&--level-2 {
    		line-height: 47px;
    		&:hover {
				background-image: linear-gradient(45deg, #FF00B5 0%, #CA00A2 100%);
				color: white;
    		}
    	}
    }

    &__NavItemLabel {
    	position: relative;
    }

    &__SubNav {
    	margin: 16px 0 0 0;
    	padding: 0;
    }


    &__Search {
    	padding: 30px;
    	border-bottom: 1px solid lightgrey;
    }

    &__SecondaryMenu {
    	overflow: hidden;
    	line-height: 82px;
    	padding: 0 30px;
    	margin: 0;
    	font-family: $font-family-primary;
    	font-size: 17px;
    }

    &__SecondaryMenuItem {
    	float: left;
		list-style: none;
		margin-right: 60px;
    }

    &__SecondaryMenuItemLabel {
    	color: $color-black;
    	&:hover, &:focus, &:active {
    		color: $color-black;
    	}
    }

    &__CloseBtn {
    	opacity: 0.2;
    	transition: 0.2s opacity;
    	font-family: $font-family-primary-bold;
    	font-size: 15px;
    	cursor: pointer;
    	float: right;
	    margin-right: 30px;
    	margin-bottom: 10px;
    	margin-top: 25px;

    	&:hover {
    		opacity: 1;
    	}
    }

    &__CloseBtnLabel {
    	display: inline-block;
    	vertical-align: middle;
    	margin-right: 10px;
    }

    &__CloseBtnIcon {
    	display: inline-block;
    	vertical-align: middle;
    }

}

