.Trustbar {

  &__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

  &__CTA {
    margin-top: 10px;

    &--center {
      @include breakpoint($screen-md) {
        text-align: center;
      }
    }
  }

	&__ItemsContainer {
		margin-top: 30px;
	}

  &__Items {
  }

  &__Item {
		margin-bottom: 30px;
  }

}
