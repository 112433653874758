.Content {
	&__PageIntroContainer {
		z-index: 1100;
	}

	&__PageIntro {
		margin-top: 30px;
		@include breakpoint($screen-md) {
			margin-top: 60px;
		}
	}

	&__MarginPageContent {
		z-index: 1100;
	}
}
