.FooterContact {
	@include container-width();
	position: relative;
	z-index: 1;
	margin: 0 auto percentage(-70/375) auto;

	@include breakpoint($screen-sm) {
		margin: 0 auto -70px auto;
	}

	@include breakpoint($screen-md) {
		margin: 0 auto -70px auto;
	}

	@include breakpoint($screen-lg) {
		margin: 0 auto -70px auto;
	}

	&__Container {
		@include bg-box-shadow();

		overflow: auto;
		color: $color-black;
		background: white;

		padding: percentage(30/337);

		@include breakpoint($screen-md) {
			padding: 30px 40px 30px 40px;
		}

		@include breakpoint($screen-lg) {
			padding: 55px 60px
		}
	}

	&__Headline {
		@include font-h3();
		margin-bottom: 20px;

		&--highlight {
			@include font-h3-bold();
			display: block;

			@include breakpoint($screen-lg) {
				display: inline;
				margin-right: 10px;
			}
		}

		@include breakpoint($screen-sm) {
			@include font-h2();

			&--highlight {
				@include font-h2-bold();
			}
		}
	}

	&__Information {
		// @include clearfix;
		@include font-p-small-bold();

		@include breakpoint($screen-sm) {
			@include font-p-bold();
		}
	}

	&__Mail {
		position: relative;
		color: $color-black;
		padding-left: 46px;
		margin-bottom: 13px;
		display: block;
		line-height: 30px;

		&:before {
			content: '';
			@include inline-svg($icon-mail);
			position: absolute;
			left: 0;
		}

		&:hover,
		&:active,
		&:focus {
			color: $color-black;
		}

		@include breakpoint($screen-md) {
			float: left;
			margin-bottom: 0;
			margin-right: 105px;
		}
		@include breakpoint($screen-lg) {
			margin-right: 0;
			display: inline-block;
			margin-right: percentage(80/1280);
		}
	}

	&__Phone {
		position: relative;
		float: left;
		color: $color-black;
		padding-left: 46px;

		&:before {
			content: '';
			@include inline-svg($icon-phone);
			position: absolute;
			left: 0;
		}

		&:hover,
		&:active,
		&:focus {
			color: $color-black;
		}

		@include breakpoint($screen-md) {
			float: none;
		}
	}

	&__InformationContainer {
		float: left;
		@include breakpoint($screen-lg) {
			margin-right: percentage(74/1280);
		}
	}

	&__Reachability {
		@include font-p-small();
		width: 100%;
		float: left;
		padding-left: 46px;

		@include breakpoint($screen-sm) {
			@include font-p();
		}
		@include breakpoint($screen-md) {
			width: auto;
			float: none;
		}
	}

	&__ReachabilityElement {
		display: block;

		@include breakpoint($screen-sm) {
			display: inline
		}
	}

	&__CTA {
		float: left;
		display: block;
		margin-top: 30px;
		width: 100%;
		@include breakpoint($screen-lg) {
			width: initial;
			margin-top: -5px;
			display: inline-block;
		}
	}
}
