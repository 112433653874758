.ContactBox {


	&__Headline {
		margin-bottom: 20px;
	}

	&__Mail {
		@include font-p-small-bold();
		line-height: 30px;
		&:before {
			content: '';
			@include inline-svg($icon-mail);
			position: absolute;
			left: 0;
		}
		a,
		a:hover,
		a:active,
		a:focus {
			color: $color-black;
		}
	}

	&__Phone {
		@include font-p-small-bold();
		margin-top: 12px;
		line-height: 30px;
		&:before {
			content: '';
			@include inline-svg($icon-phone);
			position: absolute;
			left: 0;
		}
		a,
		a:hover,
		a:active,
		a:focus {
			color: $color-black;
		}
	}

	&__Mobile {
		@include font-p-small-bold();
		a,
		a:hover,
		a:active,
		a:focus {
			color: $color-black;
		}
	}

	&__BusinessHours {
		margin-top: 5px;
	}

	&__Fax {
		@include font-p-small-bold();
		line-height: 30px;
		&:before {
			content: '';
			@include inline-svg($icon-fax);
			position: absolute;
			left: 0;
		}
		a,
		a:hover,
		a:active,
		a:focus {
			color: $color-black;
		}
	}

	&__Company {
		@include font-p-small-bold();
		margin-top: 12px;
		&:before {
			content: '';
			@include inline-svg($icon-location);
			position: absolute;
			left: 0;
		}
	}


	&__Icon {
		display: inline-block;
    	vertical-align: bottom;
	}

	&__Section {
		padding-left: 40px;
		position: relative;
	}


	&__Cols {
	}

	&__Col {
		display: inline-block;
		vertical-align: top;
		margin-right: 30px;
		margin-top: 20px;
		// @include breakpoint(670px) {
		// 	width: 50%;
		// 	float: left;
		// }
	}

	&__ColPerson {
		float: left;
	}

	&__ContactCta {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
	}

	&__ImageContainer {
		position: relative;
		margin-right: 110px;
		margin-bottom: 20px;
		@include breakpoint($screen-md) {
			margin-right: 0;
		}
	}

	&__Image {
		width: 100px;
		margin: 0 auto;
		@include breakpoint($screen-md) {
			margin: 0;
			margin-bottom: 20px;
		}
	}

	&__ImageDetails {
		display: inline-block;

		position: absolute;
		text-align: left;
	    top: 57%;
	    left: 120px;
	    transform: translateY(-50%);

	    width: 100%;

		@include breakpoint($screen-sm) {
			position: relative;
			text-align: center;
			top: 0;
			left: 0;
			transform: none;
			margin-top: 18px;
		}

		@include breakpoint($screen-md) {
			text-align: left;
			position: absolute;
			text-align: left;
		    top: 57%;
		    left: 120px;
		    transform: translateY(-50%);
		    margin-top: 0;
		}

		@include breakpoint(1120px) {
		}
	}

	&__Name {
		@include font-p-small-bold();
	}
}
