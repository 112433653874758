.SecondaryNav {
	display: inline-block;

	margin: 0;
	padding: 0;

	font-family: $font-family-primary-bold;
	font-size: 15px;




	li {
		list-style-type: none;
	}

	&__NavItem {
		color: white;
		float: left;

		padding-left: 10px;
		padding-right: 10px;
		@include breakpoint(1290px) {
			padding-left: 15px;
			padding-right: 15px;
		}

		a, a:hover, a:active, a:focus {
			color: white;
		}

		&:hover {
			 .SecondaryNav__NavItemLabel {
			 	 &:after {
					opacity: 1;
				}
			}
		}

		&:last-child {
			@include breakpoint($screen-md $screen-lg) {
				padding-left: 30px;
			}
		}

		&--search {
			cursor: pointer;
			position: relative;
			padding-left: 30px;
			&:after {
				content: '';
				position: absolute;
			    top: 50%;
			    left: 0;
			    transform: translateY(-50%);
				@include inline-svg($icon-magnifier);
			}


			&:focus {

			}
		}

		&--menu {
			cursor: pointer;
			position: relative;
			padding-right: 44px;
			&:after {
				content: '';
				position: absolute;
			    top: 50%;
			    right: 0;
			    transform: translateY(-50%);
				@include inline-svg($icon-menu);
			}


			&:focus {

			}
		}
	}

	&__NavItemLabel {
	 	position: relative;

	 	 &:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -6px;
			height: 2px;
			width: 100%;
			background-color: white;
			@include cta-shadow-white();
			transition: 0.2s opacity;
			opacity: 0;
		}
	 }
}
