.SelectButton {

	border-radius: 5px;
	border: 1px solid rgba(25, 25, 25, 0.2);

	font-family: $font-family-primary-bold;
	font-size: 15px;
	color: $color-black;
	letter-spacing: 0;
	line-height: 1;
	height: 50px;



	cursor: pointer;
	outline: none;
	text-align: center;



	&.selected {
		background-image: linear-gradient(225deg, #FF9700 0%, #FF6400 100%);
		border-color: transparent;
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
	}

	&--one {
		&:before {
			content: '';
			@include inline-svg($icon-person-one);
			display: inline-block;
		}

		&.selected {
			&:before {
				@include inline-svg($icon-person-one, #FFFFFF);
			}
		}
	}

	&--two {
		&:before {
			content: '';
			@include inline-svg($icon-person-two);
			display: inline-block;
		}

		&.selected {
			&:before {
				@include inline-svg($icon-person-two, #FFFFFF);
			}
		}
	}

	&--three {
		&:before {
			content: '';
			@include inline-svg($icon-person-three);
			display: inline-block;
		}

		&.selected {
			&:before {
				@include inline-svg($icon-person-three, #FFFFFF);
			}
		}
	}

	&--four {
		&:before {
			content: '';
			@include inline-svg($icon-person-four);
			display: inline-block;
		}

		&.selected {
			&:before {
				@include inline-svg($icon-person-four, #FFFFFF);
			}
		}
	}
}
