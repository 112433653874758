.Dropzone {
	position: relative;
	display: block;
	height: 300px;
	background-image: linear-gradient(225deg, #FF9700 0%, #FF6400 100%);
	border: 5px solid rgba(255,255,255,.5);
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease-out;
    box-shadow: 0 0 5px 0px rgba(0,0,0,.2), inset 0 0 0.25em 0 rgba(0,0,0,.25);

    @include font-p-large();
    color: white;



    &.active {
    	border: 5px solid rgba(255,255,255,.5);
    	color: $color-black;
    	background: white;

    	.Dropzone__Background {
    	}

    	.Dropzone__Icon {
    		@include inline-svg($icon-upload,  $color-black);
    		width: 100px;
    		height: 100px;
    	}
    }

    &.hasFiles {
    	.Dropzone__Icon {
    		width: 40px;
    		height: 40px;
    	}
    	.Dropzone__Explanaition {
    		@include font-p-small();
    	}
    }

    &__Background {
    	background: white;
    	opacity: .2;
    	position: absolute;
    	top: 0;
    	left: 0;
    	bottom: 0;
    	width: 0%;
    }


    &__Container {
    	position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
    	padding-left: 10%;
    	padding-right: 10%;
    	width: 100%;
    }

    &__Icon {
    	@include inline-svg($icon-upload,  #FFFFFF);
    	display: inline-block;
    	width: 100px;
    	height: 100px;
    	transition: all 0.3s ease-out;
    }

    &__SelectedFiles {
    	margin: 10px 0 0 0;
    	padding: 0;
    	@include font-p-large-bold();
    }

    &__SelectedFile {
    	list-style: none;
    }


}
