.Swoosh {

	&--pink {
		background: #FF00B4;
		background-image: url(nth($background-swoosh-pink, 1)), linear-gradient(45deg, #FF00B5 0%, #CA00A2 100%);
	}

	&--orange {
		background: #FF961E;
		background-image: url(nth($background-swoosh-orange, 1)), linear-gradient(225deg, #FF9700 0%, #FF6400 100%);
	}
}
