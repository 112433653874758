// @import 'settings';
// @import 'core';
// @import 'craft';

@import "_configuration.scss";

// Variable overrides and custom variables
@import "_variables.scss";

// BOWER packages
//------------------------------------------------------------------

@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";

// Core CSS
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";

// Utility classes
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

@import "../../bower_components/breakpoint-sass/stylesheets/_breakpoint.scss";

@import url("/libs/owl.carousel.css");

@import "_perfect-scrollbar.scss";

//------------------------------------------------------------------

@import "_mixins.scss";
@import "_text.scss";
@import "_general.scss";
@import "_svg.scss";
@import "_form-reset.scss";

// BLOCKS
//------------------------------------------------------------------

@import "blocks/_customer-referral.scss";
@import "blocks/_faq.scss";
@import "blocks/_default-list-entry.scss";
@import "blocks/_job.scss";
@import "blocks/_event.scss";
@import "blocks/_page-intro.scss";
@import "blocks/_trustbar-item.scss";
@import "blocks/_trustbar.scss";
@import "blocks/_description-item.scss";
@import "blocks/_description-list.scss";
@import "blocks/_table.scss";
@import "blocks/_download-item.scss";
@import "blocks/_downloads-element.scss";
@import "blocks/_swoosh.scss";
@import "blocks/_search-page.scss";
@import "blocks/_search-result.scss";
@import "blocks/_content.scss";
@import "blocks/_text.scss";
@import "blocks/_headline.scss";
@import "blocks/_secondary-cta.scss";
@import "blocks/_text-element.scss";
@import "blocks/_text-image-element.scss";
@import "blocks/_video-element.scss";
@import "blocks/_image-element.scss";
@import "blocks/_image.scss";
@import "blocks/_main-nav.scss";
@import "blocks/_header.scss";
@import "blocks/_header-nav.scss";
@import "blocks/_secondary-nav.scss";
@import "blocks/_search.scss";
@import "blocks/_primary-cta.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_icon.scss";
@import "blocks/_mobile-search.scss";
@import "blocks/_page.scss";
@import "blocks/_margin-col.scss";
@import "blocks/_main-teaser.scss";
@import "blocks/_background.scss";
@import "blocks/_footer.scss";
@import "blocks/_footer-contact.scss";
@import "blocks/_footer-nav.scss";
@import "blocks/_language-switch.scss";
@import "blocks/_breadcrumbs.scss";
@import "blocks/_teaser.scss";
@import "blocks/_teaser-item.scss";
@import "blocks/_accordion.scss";
@import "blocks/_video.scss";
@import "blocks/_list.scss";
@import "blocks/_event-list-item.scss";
@import "blocks/_job-list-item.scss";
@import "blocks/_default-list-item.scss";
@import "blocks/_contact-box.scss";
@import "blocks/_dimmer.scss";
@import "blocks/_modal.scss";
@import "blocks/_widget.scss";
@import "blocks/_rate-form.scss";
@import "blocks/_pricing-calculator.scss";
@import "blocks/_pricing-calculator-info.scss";
@import "blocks/_pricing-calculator-results.scss";
@import "blocks/_tooltip.scss";
@import "blocks/_sub-form.scss";
@import "blocks/_sub-form-overview.scss";
@import "blocks/_sub-form-section.scss";
@import "blocks/_pricing-calculator-table.scss";
@import "blocks/_pricing-calculator-table-list.scss";
@import "blocks/_pricing-table.scss";
@import "blocks/_dropzone.scss";
@import "blocks/_loader.scss";
@import "blocks/_application.scss";
@import "blocks/_application-header.scss";
@import "blocks/_application-result-screen.scss";
@import "blocks/_content-module.scss";
@import "blocks/_custom-widget.scss";
@import "blocks/_sticky.scss";
@import "blocks/_map.scss";
@import "blocks/_chart.scss";
@import "blocks/_co2Savings.scss";
@import "blocks/_coupon-field.scss";
@import "blocks/_footer-sharing.scss";
@import "blocks/_cookie-consent.scss";

// FORM BLOCKS
@import "blocks/form/_form-field.scss";
@import "blocks/form/_select-button.scss";
@import "blocks/form/_form-label.scss";

//------------------------------------------------------------------
