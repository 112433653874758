.Loader,
.Loader:after {
	border-radius: 50%;
	width: 4em;
	height: 4em;
}
.Loader {
	margin: 10px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 3px solid rgba(255, 255, 255, 0.2);
	border-right: 3px solid rgba(255, 255, 255, 0.2);
	border-bottom: 3px solid rgba(255, 255, 255, 0.2);
	border-left: 3px solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
