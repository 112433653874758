.CouponField {
  @include breakpoint($screen-sm) {
    display: flex;
  }

  &__Input {
    flex: 1;
  }

  &__Button {
    flex: 0;
    white-space: nowrap;
    margin-top: 20px;
    @include breakpoint($screen-sm) {
      margin-left: 40px;
      margin-right: 10px;
      margin-top: 0;
      max-height: 48px;
    }
  }
}
