.Widget {

	background-color: white;
	@include clearfix();


	margin-top: 70px;
	margin-bottom: 40px;

	@include breakpoint($screen-sm) {
		margin-top: 0;
		margin-bottom: 0;
		position: relative;
		padding: 35px 40px 40px 40px;
		@include breakpoint($screen-md) {
			padding: 40px 40px 40px 40px;
		}
		top: 0;
		overflow: visible;
	}

	&__Background {
		@include bg-box-shadow();
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    z-index: -1;
	}
}


// .js-Sticky .Widget {
// 	margin-top: -400px;
// }

// .is_stuck .Widget  {
// 	margin-top: 0;
// }
