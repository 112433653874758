.SearchResult {

	margin-top: 30px;
	margin-bottom: 30px;

	@include breakpoint($screen-sm) {
		margin-top: 40px;
		margin-bottom: 40px;
		padding: 0 30px 0 30px;
	}

	&__Headline {
		margin-top: 20px;
	}

	&__Breadcrumbs {
		margin-top: 5px;
	}

  &__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

	&__CTA {
		margin-top: 20px;
	}

}
