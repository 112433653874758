$marginTopSecondText: 20px;

.TextElement {

	&__Headline {
	}

	&__Subline {

	}

	&__FirstText {
		margin-top: 10px;
		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
		&--center {
			@include breakpoint($screen-md) {
				text-align: center;
			}
		}
	}

	&__SecondText {
		margin-top: $marginTopSecondText;
		@include breakpoint($screen-md) {
			margin-top: 15px;
		}

		&--small {
			margin-top: $marginTopSecondText;
		}
	}

	&__CTA {
		margin-top: 30px;

		&--center {
			@include breakpoint($screen-md) {
				text-align: center;
			}
		}
	}
}
