.PricingCalculatorTableList {
	margin: 0 $body-padding-xs / ($body-padding-xs * 0 + 1) * (-1vw);

	@include breakpoint($screen-sm) {
		margin: 0;
	}

	&__Headline {
		@include font-h2-bold();
		text-align: center;
		margin-bottom: 40px;

		@include breakpoint($screen-sm) {
			margin-bottom: 20px;
		}

		@include breakpoint($screen-lg) {
			@include font-h1-bold();
			margin-bottom: 40px;
		}
	}

	&__Items {
		@include breakpoint($screen-md) {
			display: flex;
		}
	}

	&__Item {
		display: flex;
		margin-bottom: 40px;
		@include breakpoint($screen-md) {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint($screen-lg) {
			margin-bottom: 0;
		}
	}

	&__Subline {
		text-align: center;
	}

	&__PriceComponentsWrap {
		text-align: right;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&__PriceComponents {
		display: inline;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 13px;
		cursor: pointer;
		background-image: linear-gradient(
			224deg,
			$color-black 0%,
			$color-black 100%
		);
		background-position: 0 1.15em;
		background-size: 100% 3px;
		background-repeat: repeat-x;

		&:hover {
			background-image: linear-gradient(224deg, #ca00a2 0%, #ff6400 100%);
		}
	}

	&__CO2Savings {
		padding: 30px $body-padding-xs 60px $body-padding-xs;
		@include breakpoint($screen-md) {
			padding: 30px 40px 60px 40px;
		}
	}
}
