/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
	position: relative;
	cursor: pointer;
	display: inline-block;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity: 0;
	pointer-events: none;
	transition: .3s all;
}

/* Position tooltip above the element */
[data-tooltip]:before {
	position: absolute;
	bottom: 150%;
	left: 50%;
	margin-bottom: 5px;
	margin-left: -103px;
	padding: 10px 5px 10px 15px;
	width: 206px;
	border-radius: 3px;
	background-color: white;
	color: $color-black;
	content: attr(data-tooltip);
	//text-align: center;
	@include bg-box-shadow();
	@include font-p-small();
	z-index: 100;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
	position: absolute;
	bottom: calc(150% - 7px);
	left: 50%;
	margin-left: -12px;
	width: 0;
	border-top: 12px solid white;
	border-top: 12px solid white;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	content: " ";
	font-size: 0;
	line-height: 0;
	z-index: 101;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1;
}
