.Application {
	&__HeaderContainer {
		background-image: linear-gradient(224deg, rgba(202,0,162,.1) 0%, rgba(255,100,0,.1) 100%);

		background-color: white;
		// box-shadow: 0 10px 25px 0 rgba(0,0,0,0);
		// transition: all 0.5s ease-in-out;
	}

	.sticky {
		z-index: 100;
		.Application__HeaderWrapper {
			padding: 0 4.29688%;
			max-width: calc(1280px + 4.29688% * 2);
    		margin: 0 auto;
			// @include bg-box-shadow();
			// transform: scale(1.02);
		}
	}

	&__HeaderSticky {
		&.sticky {
			left:0!important;
			width: 100%!important;
		}
	}

	&__Header {
		position: relative;
		overflow: auto;
	}


}
