.Accordion {

	&--static {
		margin: 0;

		@include breakpoint($screen-md) {
			margin: 0;
		}

		@include breakpoint($screen-lg) {
			margin: 0;
		}
	}

	&__Text {
		margin-top: 10px;
		@include breakpoint($screen-md) {
			margin-top: 15px;
		}

		&--center {
			@include breakpoint($screen-md) {
				text-align: center;
			}
		}
	}

	&__Header {
		margin-bottom: 30px;

		@include breakpoint($screen-md) {
			margin-bottom: 40px;
		}
	}

	&__Body {

	}

	&__List {
		padding: 0;
		margin-left: -$body-padding-xs;
		margin-right: -$body-padding-xs;
		@include breakpoint($screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__Item {
		list-style: none;
		border-bottom: 1px solid rgba(25, 25, 25, 0.2);


		padding: 0 $body-padding-xs;
		@include breakpoint($screen-sm) {
			padding: 0 30px;
		}

		&:first-child {
			border-top: 1px solid rgba(25, 25, 25, 0.2);
		}

		&.active {
			.Accordion__ItemButton {
				&:after {
					transform: translateY(-50%) rotateZ(180deg);
				}
			}
		}
	}

	&__Content {
		padding-bottom: 40px;
		@include breakpoint($screen-md) {
			padding-right: 100px;
		}
	}

	&__ItemButton {
		cursor: pointer;
		padding: 40px 0;
		position: relative;
		padding-right: 40px;
		@include breakpoint($screen-md) {
			padding-right: 100px;
		}

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			@include inline-svg($icon-arrow-down2);
			width: 18px;
			height: 18px;
			transform: translateY(-50%);
			transition: 0.2s all;
		}
	}

	&__ItemMedia {
		margin-top: 20px;
		@include breakpoint($screen-md) {
			margin-top: 20px;
			&--noMarginLarge {
				margin-top: 0;
			}
		}

		&--small {
			margin-top: 20px;
		}
	}


}
