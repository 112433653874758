.Breadcrumbs {
    padding: 0;
    overflow: auto;
    @include font-p-small();

    &__Item {
    	list-style: none;
    	float: left;
    	padding-right: 20px;
    	&:first-child {
    		&:before {
    			content: '';
    			@include inline-svg($icon-home);
			    display: inline-block;
			    line-height: 1.6;
			    margin-right: 5px;
			    transform: translateY(1px);
    		}
    	}
    	&:not(:last-child) {
    		&:after {
    			content: '';
    			@include inline-svg($icon-arrow-right);
    			display: inline-block;
    			margin-left: 20px;
    			transform: translateY(3px);
    		}
    	}

    	&:last-child {
    		font-family: $font-family-primary-bold;
    	}

    	a, a:hover, a:focus, a:active {
    		color: $color-black;
    	}
    }
}
