.CustomWidget {
	&__Btn {
		margin-top: 28px;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		@include breakpoint($screen-sm) {
			position: relative;
		}
	}

	&__OpenWidgetBtn {
		&:before {
			content: '';
			@include inline-svg($icon-person-two, #FFFFFF);
			width: 20px;
			height: 20px;
			display: inline-block;
			vertical-align: bottom;
    		margin-right: 10px;
		}
	}

	&__Text {
		@include font-p-small();
		ul {
			padding: 0;
			overflow: visible;
		}
		li {
			list-style-type: none;
			margin-top: 20px;
			margin-left: 42px;
			position: relative;
			float: left;
			@include breakpoint($screen-sm $screen-md) {
				width: 165px;
			}
			@include breakpoint(0 $screen-md) {
				margin-right: 30px;
			}
			@include breakpoint($screen-md) {
				float: none;
			}
			&:before {
				content: '';
				@include inline-svg($icon-check, $color-black);
				position: absolute;
				left: -42px;
			}
		}
	}
}
