.RateForm {
	position: relative;

	&__Headline {
		@include font-h3();
	}

	&__AnnualConsumption {
		@include clearfix();
	}

	&__SelectButtons {
    	@include breakpoint(790px $screen-md) {
			float: left;
	    	width: calc(60% + 10px);
    	}

		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;

		margin: 0 -5px;

		> * {
			flex-basis: 0;
    		flex-grow: 1;
    		margin: 0 5px 15px 5px;
		}
	}

	&__SelectButton {
		padding: 0;
	}

	&__InputWrapperKwh {
		line-height: 50px;
    	@include breakpoint(790px $screen-md) {
	    	padding-left: 20px;
			width: calc(40% - 10px);
	    	float: left;
    	}
	}

	&__InputKwh {
		width: calc(50% - 5px);
		display: inline-block;
	}

	&__InputWrapperZip {
		@include clearfix();
	}

	&__InputZip {
		width: 100%;
		// @include breakpoint($screen-sm $screen-md) {
		// 	float: left;
		// 	width: 60%;
		// }
	}




	&__Label {
		@include font-p-bold();
		margin-left: 12px;
	}

	&__AddressFields {
		overflow: auto;
		margin-left: -5px;
    	margin-right: -5px;
    	// @include breakpoint($screen-md) {
    	// 	margin-left: -0.5vw;
    	// 	margin-right: -0.5vw;
    	// }
	}

	&__AddressField {
	    overflow: auto;

	    float: left;

		display: inline-block;
    	margin-left: 5px;
    	margin-right: 5px;
    	// @include breakpoint($screen-md) {
    	// 	margin-left: 0.5vw;
    	// 	margin-right: 0.5vw;
    	// }

	 //    @include breakpoint($screen-sm $screen-md) {
		// 	width: 60%;
		// 	float: left;
		// }

		&--plz {
			width: calc(34% - 5px);
		}
		&--city {
			width: calc(62% - 5px);
		}
		&--street {
			width: calc(55% - 5px);
		}
		&--houseNumber {
			width: calc(41% - 5px);
		}

		@include breakpoint($screen-sm $screen-md) {
			&--plz {
				width: calc(15% - 5px);
			}
			&--city {
				width: calc(29% - 5px);
			}
			&--street {
				width: calc(35% - 5px);
			}
			&--houseNumber {
				width: calc(13% - 5px);
			}
		}

	}

	&__SubmitBtn {

		@include breakpoint(0 $screen-xs-max) {
			margin-top: 25px;
			clear: both;
		}

		@include breakpoint($screen-sm $screen-md) {
			margin-top: 25px;
			display: inline-block;
			// position: absolute;
			// bottom: 0;
			// display: inline-block;
			// margin-left: 20px;
			// width: calc(40% - 20px);
		}
		@include breakpoint($screen-md) {
			margin-top: 25px;
			clear: both;
		}
	}
}
