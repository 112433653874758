$tableWidth: 700px;

.Table {

	&--small {
		.Table__TableWrapper {
			@include breakpoint($screen-md $screen-md + 380px) {
				&:after {
					pointer-events: none;
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 10%;
					opacity: 0.1;
					background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #000000 100%);
				}
			}
		}
	}

	&__Text {
		margin-top: 10px;
		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}


	&__TableWrapper {
		position: relative;

		@include breakpoint(0 $tableWidth + 70px) {
			&:after {
				pointer-events: none;
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				width: 10%;
				opacity: 0.1;
				background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #000000 100%);
			}
		}
	}

	&__Table {
		margin-top: 30px;
		//overflow: scroll;
		position: relative;
		overflow: hidden;

		@include breakpoint($screen-md) {
			margin-top: 40px;
		}

		a {
		color: $color-black;
		background-image: linear-gradient(224deg, $color-black 0%, $color-black 100%);
		background-position: 0 1.15em;
		background-size: 100% 3px;
		background-repeat: repeat-x;

			&:hover {
				background-image: linear-gradient(224deg, #CA00A2 0%, #FF6400 100%);
			}
		}

	}

	table {
		@include font-p-small();

		background-image: linear-gradient(225deg, #FF9700 0%, #FF6400 100%);
		background-size: 100% 60px;
		background-repeat: no-repeat;
		table-layout: fixed;
		width: 100%;
		min-width: $tableWidth;
		border-radius: 4px 4px 0 0;
		border-collapse: separate;

		thead {
			@include font-p();

			line-height: 1;
			text-align: left;
			color: white;

			tr {
				height: 60px
			}

			th, td {
				padding-top: 0;
				padding-bottom: 0;
			}

		}

		th, td {
			padding: 18px 20px;
		}

		td {
			vertical-align: top;
		}

		tbody {

			tr:last-child td:first-child {
				border-bottom-left-radius: 4px;
			}

			tr:last-child td:last-child {
				border-bottom-right-radius:4px
			}

			tr:nth-child(odd) {
				background-color: rgba(25, 25, 25, 0.03);
			}

			tr:nth-child(even) {
				background-color: rgba(25, 25, 25, 0.08);
			}
		}

	}

}
