.Footer {

	margin-top: percentage(200/375);

	@include breakpoint($screen-sm) {
		margin-top: 120px;
	}

	color: white;

	&__Container {
		@include container-width();
		margin-top: 155px;
		margin-bottom: 40px;
	}

	&__SecondaryNav {
		margin: 20px 0 0 -20px;
		padding: 0;


		display: flex;
		justify-content: flex-start;
		flex-flow: row wrap;

		@include breakpoint($screen-md) {
			margin-left: 0;
			margin-top: 40px;
			justify-content: center;
		}


		li {
			list-style: none;

		}
	}

	&__SecondaryNavItem {
		margin: 20px 0 0 20px;
		@include breakpoint($screen-md) {
			margin-top: 0;
		}
	}

	&__SecondaryNavItemLabel {
		font-size: 15px;
		color: white;
		opacity: 0.75;

		&:hover,
		&:focus,
		&:active {
			color: white;
		}
	}
}
