.DefaultListEntry {

	margin-top: 40px;
	// margin-bottom: 40px;

	@include breakpoint($screen-sm) {
		margin-top: 50px;
	// margin-bottom: 50px;
	}

	@include breakpoint($screen-md) {
		margin-top: 60px;
	// margin-bottom: 60px;
	}

	&__Meta {
		overflow: auto;
		text-align: center;
	}

	&__Date {
		@include font-p-small();

		float: left;

		@include breakpoint($screen-sm) {
			float: none;
			text-align: center;
			display: inline-block;
			padding-right: 30px;
		}
	}

	&__Location {
		@include font-p-small();
		float: right;

		@include breakpoint($screen-sm) {
			float: none;
			text-align: center;
			display: inline-block;
		}

		&:before {
			content: '';
			@include inline-svg($icon-location);
			width: 25px;
				height: 25px;
				display: inline-block;
				vertical-align: bottom;
				margin-right: 7px;
				margin-left: -3px;
		}
	}

	&__Headline {
		margin-top: 20px;

		@include breakpoint($screen-sm) {
			text-align: center;
		}

		@include breakpoint($screen-md) {
			margin-top: 30px;
		}
	}

	&__Subline {
		@include font-p-bold();
		margin-top: 5px;

		@include breakpoint($screen-sm) {
			text-align: center;
		}

		@include breakpoint($screen-md) {
			text-align: center;
		}
	}

	&__Text {
		margin-top: 10px;

		@include breakpoint($screen-sm) {
			margin-top: 15px;
			text-align: center;
		}
	}

	&__Image {
		margin-top: 30px;

		@include breakpoint($screen-md) {
			margin-top: 40px;
		}
	}

}
