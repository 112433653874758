.PricingCalculatorTable {
	&__Container {
		background: #fff4ed;
		width: 100%;

		&--member {
			background: #fbedf8;

			.PricingCalculatorTable__Header {
				background-size: 150%, cover;
				background-position: 130% 80%, 50% 50%;
			}
		}
	}

	&__Header {
		color: white;
		background-size: 200%, cover;
		background-position: 0% 82%, 50% 50%;
		text-align: center;
		height: 100px;
		padding-top: 19px;

		@include breakpoint($screen-sm) {
			height: 120px;
			padding-top: 25px;
		}
	}

	&__Headline {
		@include font-h3-bold();

		@include breakpoint($screen-sm) {
			@include font-h2-bold();
		}
	}

	&__Subline {
		@include font-p();

		span:first-child {
			@include font-p-bold();
		}

		@include breakpoint($screen-sm) {
			margin-top: -7px;
		}
	}

	&__Body {
		padding: 30px $body-padding-xs 60px $body-padding-xs;
		@include breakpoint($screen-md) {
			padding: 30px 40px 60px 40px;
		}
	}

	&__Intro {
		margin-bottom: 30px;
	}

	&__IntroHeadline {
		@include font-p-small-bold();
		margin-bottom: 15px;

		@include breakpoint($screen-sm) {
			@include font-p-bold();
			margin-bottom: 10px;
		}
	}

	&__List {
		@include font-p-small();
		margin: 0;
		padding: 0;

		@include breakpoint($screen-sm) {
			@include font-p();
		}
	}

	&__ListItem {
		list-style: none;
		position: relative;
		margin-left: 45px;
		margin-bottom: 5px;

		@include breakpoint(0 $screen-xs-max) {
			margin-left: 30px;
			margin-bottom: 10px;
		}

		&:before {
			content: " ";
			@include inline-svg($icon-check, $color-black);
			position: absolute;
			left: -45px;

			@include breakpoint(0 $screen-xs-max) {
				width: 20px;
				height: 20px;
				left: -30px;
			}
		}
	}

	&__Button {
		margin-top: 30px;
	}

	&__MemberInfo {
		margin-top: 40px;
	}

	&__CTA {
		margin-top: 20px;
	}
}
