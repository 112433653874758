.Sticky {
	&__Container {
		position: relative;
	}

	&__JsContainer {
		position: relative;
		z-index: 1000;
	}

	&__Wrapper {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;


	    &--largeHeader {
	    	margin-top: -15vw;
	    	@include breakpoint(1920px) {
	    		margin-top: -300px;
	    	}
	    }

	    &--mediumHeader {
	    	margin-top: -14vw;
	    	@include breakpoint(1920px) {
	    		margin-top: -268px;
	    	}
	    }

	    &--smallHeader {
	    	margin-top: -10vw;
	    	@include breakpoint(1920px) {
	    		margin-top: -209px;
	    	}
	    }

	    // &--largeHeader {
	    // 	top: -25vw;
	    // 	@include breakpoint(1920px) {
	    // 		top: -480px;
	    // 	}
	    // }

	    // &--mediumHeader {
	    // 	top: -14vw;
	    // 	@include breakpoint(1920px) {
	    // 		top: -268px;
	    // 	}
	    // }

	    // &--smallHeader {
	    // 	top: -15vw;
	    // 	@include breakpoint(1920px) {
	    // 		top: -288px;
	    // 	}
	    // }
	}
}
