.Text {
  @include font-p();

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--largeCenter {
    @include breakpoint($screen-md) {
      text-align: center;
    }
  }

  &--bold {
    @include font-p-bold();
  }

  &--pSmall {
    @include font-p-small();
  }

  &--pSmallBold {
    @include font-p-small-bold();
  }

  p {
    // margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-black;
    background-image: linear-gradient(
      224deg,
      $color-black 0%,
      $color-black 100%
    );
    background-position: 0 1.15em;
    background-size: 100% 3px;
    background-repeat: repeat-x;

    &:hover {
      background-image: linear-gradient(224deg, #ca00a2 0%, #ff6400 100%);
    }
  }
}
