@include add-breakpoint('screen-xs', $screen-xs $screen-xs-max);
@include add-breakpoint('screen-sm', $screen-sm $screen-sm-max);
@include add-breakpoint('screen-md', $screen-md $screen-md-max);
@include add-breakpoint('screen-lg', $screen-lg);

@include add-breakpoint('phone', $screen-xs $screen-xs-max);
@include add-breakpoint('tablet', $screen-sm $screen-sm-max);
@include add-breakpoint('desktop', $screen-md $screen-md-max);
@include add-breakpoint('wide-desktop', $screen-lg);

@include breakpoint($screen-lg) {
    .auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@include breakpoint($screen-md $screen-md-max) {

    .auto-clear .col-md-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-md-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-md-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-md-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@include breakpoint($screen-sm $screen-sm-max) {
    .auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@include breakpoint(0 $screen-xs-max) {
    .auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-xs-6:nth-child(odd){clear:left;}
}


@mixin make-grid-columns($name, $i: 1, $list: ".col-xs#{$name}-#{$i}, .col-sm#{$name}-#{$i}, .col-md#{$name}-#{$i}, .col-lg#{$name}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs#{$name}-#{$i}, .col-sm#{$name}-#{$i}, .col-md#{$name}-#{$i}, .col-lg#{$name}-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}

@mixin container-width() {
	max-width: calc(1280px + #{$body-padding-md} * 2);
  margin: 0 auto;
  padding: 0 $body-padding-xs;
  @include breakpoint($screen-sm) {
  	padding: 0 $body-padding-sm;
  }
  @include breakpoint($screen-md) {
  	padding: 0 $body-padding-md;
  }
}


$narrow-width-columns: 7;
$grid-columns: $narrow-width-columns;

@include make-grid-columns('small');
@include make-grid('n-xs');
@include make-grid('n-sm');
@include make-grid('n-md');
@include make-grid('n-lg');



$grid-columns: 12;

$gutter-width-xs: 2vw;
$gutter-width-sm: 1.3vw;
$gutter-width-md: 1.2vw;
$gutter-width-lg: 20px;
$body-padding-xs: percentage(19/375);
$body-padding-sm: percentage(34/768);
$body-padding-md: percentage(55/1280);


body {
	font-family: $font-family-primary, "Arial", "sans-serif";
	color: $color-black;
	margin: 0 auto;

	text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
  	overflow: hidden;
  }
}

main {
	@include container-width();
}

.row {
	@include make-row();
	margin-left: -$gutter-width-xs;
	margin-right: -$gutter-width-xs;
	@include breakpoint($screen-sm) {
  	margin-left: -$gutter-width-sm;
  	margin-right: -$gutter-width-sm;
  }
  @include breakpoint($screen-md) {
  	margin-left: -$gutter-width-md;
  	margin-right: -$gutter-width-md;
  }
  @include breakpoint($screen-lg) {
  	margin-left: -$gutter-width-lg;
  	margin-right: -$gutter-width-lg;
  }
}

.container-fluid {
	padding: 0;
}

div[class^="col-"], div[class*=" col-"] {
	padding: 0 $gutter-width-xs;
	@include breakpoint($screen-sm) {
    padding: 0 $gutter-width-sm;
  }
  @include breakpoint($screen-md) {
  	padding: 0 $gutter-width-md;
  }
  @include breakpoint($screen-lg) {
  	padding: 0 $gutter-width-lg;
  }
}

ul {
	padding-left: 20px;
  margin: 0;
}

h1, h2, h3, h4, h5 {
	margin: 0;
	font-weight: normal;
}


// PERFECT-SCROLLBAR

.ps-scrollbar-x-rail,
.ps-scrollbar-y-rail {
	display: none;
}


// REACT DATE PICKER OVERRIDES

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0.5rem !important;
}

.react-datepicker__input-container {
	width: 100%;
}
