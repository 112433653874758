.FooterNav {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  &__Level1Container {
    li {
      list-style: none;
      padding: 0;
      padding-top: 14px;
    }

    padding: 0 20px 0 0;
    margin: 0 0 20px 0;
    width: 50%;
    @include breakpoint($screen-md) {
      max-width: 232px;
      width: auto;
    }
  }

  &__SubNav {
    padding: 0;
  }

  &__NavItemLabel {
    color: white;

    &--level-1 {
      font-family: $font-family-secondary-bold;
      font-size: 17px;
      line-height: 24px;

      @include breakpoint($screen-md) {
        line-height: 30px;
      }
    }

    &--level-2 {
      font-size: 15px;
      opacity: 0.75;
    }
  }

  &__LanguageSwitch {
    width: 100%;
    padding: 40px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    @include breakpoint($screen-lg) {
      padding: 0;
      margin: 0;
      border: none;
      width: auto;
    }
  }

  &__LanguageSwitchTitle {
    font-family: $font-family-secondary-bold;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 16px;
    padding-top: 17px;
  }

  &__SharingTitle {
    font-family: $font-family-secondary-bold;
    font-size: 17px;
    line-height: 24px;
    padding-top: 14px;
    margin-bottom: 12px;
  }
}
