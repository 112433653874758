.Chart {
	position: relative;
	margin-bottom: 20px;
	&__Wrapper {
		position: relative;
		padding-bottom: 70%;
		height: 0;
		overflow: hidden;
	}
	&__Content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__Row {
		position: relative;
	}

	&__Tooltip {
		font-size: 14px;
		text-align: left;
	}

	&__TooltipHeadline {
		font-family: $font-family-secondary-bold;
	}

	&__LegendCol {
		@include breakpoint($screen-md) {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__SegmentText {
		transform: translate(-50%, -50%);
		color: white;
		font-family: $font-family-secondary-bold;
		font-size: 22px;
	}

	&__SegmentTextWrapper {
		pointer-events: none;
	}

	&__Legend {
		list-style: none;
		margin-left: 20px;
	}

	&__LegendItem {
		position: relative;
		margin: 26px 0;
		font-size: 15px;
	}

	&__LegendItemDot {
		vertical-align: middle;
		font-size: 60px;
		padding-right: 10px;
		position: absolute;
		left: -35px;
		top: -30px;
	}

	&__LegendItemLabel {
		vertical-align: middle;
	}
}

.google-visualization-tooltip {
	box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.55) !important;
	border: none !important;
	border-radius: 4px !important;
}

body {
	background: white;
	//font-family: "Oswald", sans-serif;
}
.chart {
	position: absolute;
	width: 20%;
	height: 20vw;
	top: 50%;
	left: 50%;
	margin: -225px 0 0 -225px;
}
.doughnutTip {
	position: absolute;
	transform: translateY(-50%);
	min-width: 30px;
	max-width: 300px;
	padding: 5px 15px;
	border-radius: 4px;
	background: white;
	color: black;
	font-size: 17px;
	z-index: 100000;

	text-align: center;
	line-height: 1.3;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	pointer-events: none;
	&::after {
		position: absolute;
		left: 50%;
		bottom: -6px;
		content: "";
		height: 0;
		margin: 0 0 0 -6px;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-top: 6px solid white;
		line-height: 0;
	}
}
.doughnutSummary {
	position: absolute;
	top: 50%;
	left: 50%;
	color: #d5d5d5;
	text-align: center;
	text-shadow: 0 -1px 0 #111;
	cursor: default;
}
.doughnutSummaryTitle {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -27%;
	font-size: 22px;
	letter-spacing: 0.06em;
}
.doughnutSummaryNumber {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -15%;
	font-size: 55px;
}
.chart path:hover {
	opacity: 0.65;
}
