$marginTopCTA: 30px;

.CustomerReferralElement {

	&__Headline {
		margin-top: 20px;
		@include breakpoint($screen-sm) {
			margin-top: 0;
		}
	}

	&__Subline {
	}

	&__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

	&__TextBlock {
	}

	&__CTA {
		position: relative;
		margin-top: $marginTopCTA;

		&:last-child {
			margin-top: 20px;
		}

		&:last-child a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		@include breakpoint($screen-sm) {
			&--small {
				float: left;
				margin-top: $marginTopCTA;
				margin-right: 80px;

				&:last-child {
					margin-right: 30px;
					margin-top: $marginTopCTA;
				}
			}
		}

		@include breakpoint($screen-md) {
			float: left;
			margin-right: 80px;

			&:last-child {
				margin-right: 30px;
				margin-top: $marginTopCTA;
			}
		}

	}

}
