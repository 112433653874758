.PrimaryCta {
	/*Reset's every elements apperance*/
    background: none repeat scroll 0 0 white;
    border: medium none;
    border-spacing: 0;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.42rem;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    text-indent: 0;



	box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
	border-radius: 5px;

	font-family: $font-family-primary-bold;
	font-size: 15px;
	color: $color-black;
	letter-spacing: 0;
	line-height: 20px;

	padding: 14px 20px;
	//margin-top: 4px;
	// margin: 4px 8px;
	cursor: pointer;
	display: inline-block;


	transition: 0.1s all;

	&:hover {
		&:not(.disabled) {
			transform: scale(1.05);
		}
		&:not(:disabled) {
			transform: scale(1.05);
		}
	}

	&:disabled {
		opacity: .5;
		transform: scale(1)!important;
	}

	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: none;
	}


	&--orange {
		background-image: linear-gradient(225deg, #FF9700 0%, #FF6400 100%);
	}

	&--pink {
		background-image: linear-gradient(45deg, #FF00B5 0%, #CA00A2 100%);
	}

	&--green {
		background: #0AC789;
	}

	&--orange,
	&--pink,
	&--green {
		color: white;
		&:hover,
		&:active,
		&:focus {
			color: white;
		}
	}

	&.disabled {
		opacity: 0.2;
	}

	&--fullWidth {
		width: 100%;
		padding: 14px 20px;
		margin: 0;
		text-align: center;
	}

	&--textCenter {
		text-align: center;
	}

	&--fullWidthXS {
		@include breakpoint($screen-xs $screen-xs-max) {
			width: 100%;
			padding: 14px 20px;
			margin: 0;
			text-align: center;
		}
	}

	&--flat {
		box-shadow: none;
		border-radius: 0;
		height: 70px;
		padding: 0;
    	line-height: 70px;
		&:hover {
			&:not(.disabled) {
				transform: scale(1);
			}
		}
	}


	&__Icon {
		display: inline-block;
	    vertical-align: middle;
	    margin-right: 7px;
	}

	&__Label {
		display: inline-block;
	    vertical-align: middle;
	}



}
