.Teaser {
	text-align: center;
	position: relative;


	&__Subline {
	}

	&__Text {
		@include font-p();
		text-align: center;
		margin-top: 15px;
		p {
			margin: 0;
		}
	}

	&__Header {
		margin-bottom: 30px;
		@include breakpoint($screen-md) {
			margin-bottom: 40px;
		}
	}


	&--half {
		.Teaser__Item {
			margin-bottom: 40px;
			@include breakpoint($screen-md) {
				margin-bottom: 0;
			}
		}

		.Teaser__Item {
			&:nth-child(n+3) {
				margin-top: 60px;
			}
		}
	}

	&--thirdLarge {
		.Teaser__Item {
			margin-bottom: 20px;
			@include breakpoint($screen-md) {
				margin-bottom: 0;
			}
		}
	}

	&__RightCol {
		> :first-child {
			@include breakpoint($screen-md) {
				margin-bottom: 6.5%;
			}
			@include breakpoint($screen-lg) {
				margin-bottom: 7.5%;
			}
		}
	}



	&__Item {
		margin-bottom: 20px;
		@include breakpoint($screen-md) {
			margin-bottom: 0;
		}
	}


	&__OwlContainer {
		@include breakpoint(0 $screen-sm-max) {
			margin-right: -$body-padding-xs * 1.1;
			@include breakpoint($screen-sm) {
				margin-right: -$body-padding-sm * 1.1;
			}
		}
		&.owl-carousel {
			width: auto;
		}

		.owl-stage {
			margin-left: -50px;
		}

		.owl-item {
			padding-right: $gutter-width-sm * 2;
		}

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 18%;
		    z-index: 100;
			opacity: 0.9;
			background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
			pointer-events: none;
		}
	}

}
