.DownloadItem {

  &__Info {
    margin-top: 5px;
  	font-size: 15px;
  	line-height: (32/15);
  }

  &__InfoMeta {
    font-family: $font-family-primary-bold;
  }

  &__Download {
    @include font-p-bold();
    margin-top: 15px;
    padding-left: 40px;
    position: relative;

    &:before {
      content: '';
      @include inline-svg($icon-download);
      position: absolute;
			left: 0;
    }
    a,
    a:hover,
    a:active,
    a:focus {
      color: $color-black;
    }
  }

}
