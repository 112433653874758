.PricingCalculatorInfo {


	&__Headline {
		@include font-h2-bold();
		margin-bottom: 10px;

		@include breakpoint($screen-lg) {
			margin-bottom: 15px;
		}

		@include breakpoint($screen-lg) {
			@include font-h1-bold();
		}
	}

	&__Text {
		@include font-p();
		padding: 0;
		margin: 0;
		margin-bottom: 30px;
	}

	&__List {
		padding: 0;
		list-style: none;
		@include clearfix;

		@include breakpoint($screen-sm) {
			margin: 0;
		}
	}

	&__Item {
		width: 100%;
		box-shadow: 0 -1px 0 0 rgba(25,25,25,0.20);


		$PricingCalculatorInfo__ItemWidth: percentage(1/3);
		@include breakpoint($screen-sm) {
			box-shadow: none;
			float: left;
			width: calc(#{$PricingCalculatorInfo__ItemWidth} - (50/3) * 1px);
			margin-right: 25px;
		}

		@include breakpoint($screen-lg) {
			width: calc(#{$PricingCalculatorInfo__ItemWidth} - (80/3) * 1px);
			margin-right: 40px;
		}

		&:last-child {
			box-shadow: 0 1px 0 0 rgba(25,25,25,0.20), 0 -1px 0 0 rgba(25,25,25,0.20);
			@include breakpoint($screen-sm) {
				box-shadow: none;
				margin-right: 0;
			}
		}

	}

	&__ItemHeadline {
		@include font-h5-bold();
		padding: percentage(30/375) percentage(18/375) percentage(31/375) percentage(20/375);
		margin-right: 20px;

		&:after {
			content: ' ';
			@include inline-svg($icon-arrow-down2, $color-black);
		    display: inline-block;
		    float: right;
		    width: 18px;
		    height: 18px;
		    margin-right: -20px;
		}

		@include breakpoint($screen-sm) {
			padding: 0;
			margin-right: 0;
			margin-bottom: 10px;

			&:after {
				content: '';
				display: none;
			}
		}
	}

	&__ItemText {
		@include font-p-small();

		display: none;
		padding: percentage(30/375) percentage(75/375) percentage(31/375) percentage(20/375);
		margin: 0;

		@include breakpoint($screen-sm) {
			display: block;
			padding: 0;
		}
	}
}
