.MarginCol {



	@include breakpoint($screen-md) {
		position: relative;
	    z-index: 1100;
	}


    &--largeHeader {
    	margin-top: -15vw;
    	@include breakpoint(1920px) {
    		margin-top: -300px;
    	}
    }

    &--mediumHeader {
    	margin-top: -14vw;
    	@include breakpoint(1920px) {
    		margin-top: -268px;
    	}
    }

    &--smallHeader {
    	margin-top: -10vw;
    	@include breakpoint(1920px) {
    		margin-top: -209px;
    	}
    }


    &__Button {
    	// margin-bottom: 40px;
    	text-align: right;
    	// @include breakpoint($screen-sm) {
    	// 	margin-bottom: 50px;
    	// }
    }
}
