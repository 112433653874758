.ImageElement {

	&__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

	&__Image {
		margin-top: 30px;
	}

	&__Caption {
		margin-top: 23px;
		@include breakpoint($screen-sm) {
			margin-top: 20px;
		}
		@include breakpoint($screen-md) {
			margin-top: 30px;
		}
	}

}
