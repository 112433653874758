.PricingTable {
	width: 100%;

	&__Header {
		@include font-p-small-bold();

		vertical-align: top;
		text-align: left;
		width: percentage(120/540);
		padding-bottom: 8px;

		@include breakpoint($screen-sm) {
			@include font-p-bold();
			padding-bottom: 0;
		}
	}

	&__Cell {
		@include font-p-small();
		vertical-align: top;
		text-align: right;
		width: 72px;

		@include breakpoint($screen-sm)	{
			@include font-p();
			width: 77px;
		}


		&--Dash {
			position: relative;
			padding-bottom: 15px;
			&:after {
				content: ' ';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 2px;
				background: #FF9700;
				box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
			}

		}

		&--Empty {
			width: auto;
		}

		&--Result {
			font-family: $font-family-secondary-bold;
			font-size: 38px;
			line-height: 1;
			padding-top: 19px;
			width: auto;

			@include breakpoint($screen-sm)	{
				font-size: 50px;
			}
		}
	}

	&__Period {
		@include font-p-small();
		display: block;
		padding-top: 5px;

		@include breakpoint($screen-sm)	{
			@include font-p();
		}
	}

	&__PlusSign {
		float: left;
	}

	&--member {
		.PricingTable__Cell:after {
			background: #FF00B5;
		}
	}
}


.Total {
	&__Basis {

	}

	&__Sup {
		font-size: percentage(21/50);
		vertical-align: super;
		margin-left: -6px;
	}

	&__Currency {

	}
}
