.PricingCalculator {

	background-color: white;

	box-shadow: 0 10px 25px 0 rgba(0,0,0,0.1);
	@include breakpoint($screen-sm) {
		@include bg-box-shadow();
	}



	//margin: 0 calc(-#{$body-padding-xs} / 1%);
	padding: percentage(30/375) percentage(19/375);

	margin: 0 $body-padding-xs / ($body-padding-xs * 0 + 1) * (-1vw);
	@include breakpoint($screen-sm) {
		padding: percentage(37/700) percentage(40/700);
		margin: 0;
	}

	@include breakpoint($screen-md) {
		margin-top: 60px;
	}

	@include breakpoint($screen-lg) {
		padding: percentage(35/1280) percentage(60/1280) percentage(40/1280) percentage(60/1280);
	}


	&__Info {
		padding-top: percentage(14/620);
		padding-bottom: percentage(50/620);
		margin-bottom: percentage(40/620);


		@include breakpoint($screen-sm) {
			box-shadow: 0 1px 0 0 rgba(25,25,25,0.20);
		}

		@include breakpoint($screen-md) {
			padding-bottom: 0;
			box-shadow: none;
			margin-bottom: 0;
		}
	}

	&__RateForm {

		@include breakpoint($screen-md) {
			padding-left: percentage(60/460);
			// box-shadow: -1px 0 0 0 rgba(25,25,25,0.20);
			margin-left: percentage(79/460);
		}
	}

	&__Separator {
		@include breakpoint($screen-md) {
			position: absolute;
		    top: 0;
		    bottom: 0;
		    width: 1px;
		    background: rgba(25,25,25,.2);
		    left: 59.5%;
		    @include breakpoint($screen-lg) {
		    	left: 66.5%;
		    }
		}
	}

	&__Container {
		position: relative;
	}
}


