.FooterSharing {
  &__Logo {
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: inline-block;
    border-radius: 50%;
    transition: 0.35s all;
    margin-right: 30px;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.8);
    }
  }
  &__YoutubeLogo {
    @include inline-svg($icon-youtube-logo, #FFFFFF);
  }
  &__FacebookLogo {
    @include inline-svg($icon-facebook-logo, #FFFFFF);
    background-size: contain;
    width: 23px;
  }
}
