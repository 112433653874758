select {
	&.LanguageSwitch {
		padding: 13px 83px 13px 15px;
		border-radius: 5px;
		border: 1px solid rgba(255,255,255,0.2);
		outline: none;
		text-transform: capitalize;

		@include inline-svg($icon-arrow-down-white);
		width: auto;
		height: auto;
		background-size: 20px;
		background-position-x: 90%;
		background-color: transparent;

		font-family: $font-family-primary-bold;
		color: white;
		font-size: 16px;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;

		option {
			color: black !important;
		}
	}
	&::-ms-expand {
		display: none;
	}
}
