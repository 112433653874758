.DownloadsElement {

	&__Text {
		margin-top: 10px;

		@include breakpoint($screen-md) {
			margin-top: 15px;
		}
	}

	&__Items {
	}


	&__ItemContainer {
		margin-top: 30px;
		padding-top: 30px;

		@include breakpoint($screen-md) {
			margin-top: 40px;
			padding-top: 40px;
		}

		border-top: 1px solid rgba(25, 25, 25, 0.2);
	}

	&__Item {


		&:first-child {

			.DownloadsElement__ItemContainer {
				border-top: 0;
				margin-top: 0;
			}

		}

		&:nth-child(2) {

			.DownloadsElement__ItemContainer {
				@include breakpoint($screen-md) {
					border-top: 0;
					margin-top: 0;
				}

				&--small {
					margin-top: 30px;
					padding-top: 30px;

					@include breakpoint($screen-md) {
						margin-top: 40px;
						padding-top: 40px;
					}

					border-top: 1px solid rgba(25, 25, 25, 0.2);
				}
			}

		}

	}

}
