.ContentModule {
	margin-top: 70px;

	@include breakpoint($screen-sm) {
		margin-top: 90px;
	}

	@include breakpoint($screen-md) {
		margin-top: 120px;
	}
}
