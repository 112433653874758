.MainTeaser {
	margin: 100px 0 40px 0;

	@include breakpoint($screen-sm) {
		margin: 116px 0 51px 0;
	}

	@include breakpoint($screen-lg) {
		margin: 120px 0 60px 0;
	}

	&--orange {
		.MainTeaser__Container {
			background-image: url(/img/bg-main-teaser-orange.png);
    		background-size: cover;
		}
	}

	&--pink {
		.MainTeaser__Container {
			background-image: url(/img/bg-main-teaser-pink.png);
    		background-size: cover;
		}
	}

	&__Container {
		position: relative;
		margin: 0 $body-padding-xs / ($body-padding-xs * 0 + 1) * (-1vw);

		@include breakpoint($screen-sm) {
			margin: 0;
		}
	}

	&__BodyContainer {
		position: relative;
		height: 168px;
		z-index: 2;

		@include breakpoint($screen-sm) {
			min-height: 300px;
			height: 317/768 * 100vw;
		}

		@include breakpoint($screen-md) {

		}

		@include breakpoint($screen-lg) {
			height: 580/1400 * 100vw;
			max-height: 580px;
		}
	}

	&__Image {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;

		&--right {
			left: initial;
			right: 0;
		}


		@include breakpoint($screen-sm) {
			width: 90%;
		}

		@include breakpoint($screen-md) {
			width: 100%;
		}
	}

	&__Body {
		color: $color-black;
		margin-top: 20px;
		@include breakpoint($screen-sm) {
			width: 30%;
			margin-top: 0;
			color: white;
			transform: translateY(-50%);
			position: absolute;
			top: 50%;
			// left: percentage(440/700);
			right: 6%;
		}

		@include breakpoint($screen-lg) {
			// left: percentage(821/1280);
			// right: percentage(20/1280);
		}

		&--left {
			right: initial;
			@include breakpoint($screen-sm) {
				left: 9%;
				// right: percentage(390/700);
			}

			@include breakpoint($screen-lg) {
				// left: percentage(110/1280);
				// right: percentage(720/1280);
			}
		}
	}

	&__Headline {
		@include font-h3-bold();

		@include breakpoint($screen-lg) {
			@include font-h1-bold();
		}
	}



	&__Text {
		@include font-h3();

		@include breakpoint($screen-lg) {
			@include font-h1();
		}
	}

	&__Button {
		margin-top: 15px;
		@include breakpoint($screen-lg) {
			margin-top: 46px;
		}
	}
}
